interface TooltipProps {
    message: string,
    children: React.ReactNode,
    location?: 'top' | 'bottom' | 'left' | 'right',
}

export const Tooltip: React.FC<TooltipProps> = ({ message, children, location = 'top' }) => {

    let locationClass = 'top-0 left-1/2 transform -translate-x-1/2 -translate-y-full';
    
    switch(location) {
        case 'bottom':
            locationClass = 'bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full';
            break;
        case 'left':
            locationClass = 'left-0 top-1/2 transform -translate-x-full -translate-y-1/2';
            break;
        case 'right':
            locationClass = 'right-0 top-1/2 transform translate-x-full -translate-y-1/2';
            break;
    }

    return (
    <div className="group relative flex">
        {children}
        <span className={`absolute ${locationClass} scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 w-full`}>{message}</span>
    </div>
    )
}