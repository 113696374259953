export interface AdminUser {
    _id: string
    name: string
    Tokens: AdminToken[]
    Active: boolean
}

export enum AdminToken {
    VIEW_ACCESS_LOGS = 'CAN_VIEW_ACCESS_LOGS',
    MANAGE_COMPANY = 'CAN_MANAGE_COMPANY',
    MANAGE_SUPER_ADMIN_USER = 'CAN_MANAGE_SUPER_ADMIN_USER',
    ALL = 'ALL'
}