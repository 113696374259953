import { useMemo, useRef, useState } from 'react';
import {
  Form,
  FormDefinition,
  FormItem,
  FormItems,
  FormRef,
  FormType,
  SubmitBtn,
} from '../../../components/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Tenant } from '../../../types/Tenant';
import { useFlexLayer } from '../../../hooks/useFlexlayer';
import { CustomerInfo, HidefieldMeta } from '../../../types/Customer';
import storage from '../../../utils/storage/storage';
import { AdminStorageKey } from '../../../constant/constant';
import { AdminToken, AdminUser } from '../../../types/Admin';
import { ComponentLoading } from '../../../shared/component-loader/component-loading';
import { showMessage } from '../../../utils/utils';
import { checkoutRequireFields, serviceRequestRequireFields } from './requiredFields';
import { UserRoles } from '../../../shared/info/permissions';
import { AuthZeroSettings } from './authZeroSettings';
import { GeneralSetting } from './generalSetting';


/**
 * @description This component handles the creation and editing of customers
 * when linking a customer, it comes from the EAutomate soap service
 * @returns JSX.Element
 */


interface IAuthZeroSettings {
  newUserDefaults: {
    Tokens: string[],
    RestrictUserByLocationID: string[],
    PlaceOrderAddressID: string[],
    ServiceRequestAddressID: string[],
    CConNonWebOrders: boolean,
    HideOtherUserOrders: boolean,
  },
  allowAutoCreateNewUser: boolean,
  emailDomains: string[],
}

interface CustomerOptions {
    CustomerData: CustomerInfo,
    HideField: HidefieldMeta,
    RequiredField: any,
    AuthZeroSettings: IAuthZeroSettings,
}

//Hard coded search type options, should probably move these somewhere if they're actually volatile
const searchTypeOptions = [
  { label: 'Transactional', value: 'TR' },
  { label: 'Covered On Contract', value: 'COC' },
  { label: 'Combined (Contract & Transactional)', value: 'COC-TR' },
];


const contactOptions = [
  { label: 'Logged in User', value: 'loggedInUser' },
  { label: 'Location Contact', value: 'locationContact' }
];

// Default values for the hide meta fields for new customers
const defaultHideMetaForNewCustomer = {
    cc: false,
    department: false,
    floor: false,
    orderPhone: false,
    poNumber: false,
    address: false,
    attention: false,
    comments: false,
    description: false,
    email: false,
    name: false,
    orderEmail: false,
    phone: false,
    extendedNotes: false,
    primarySymptom: false,
}

// Default values for new customers, when linking them from EAutomate
const defaultNewCustomerData: Partial<CustomerInfo> = {
  WarnCustomerOfPotentialDuplicateOrders: true,
  HideZeroDollarPricing: true,
  HideEquipmentOrders: true,
  AllowReportView: true,
  TriageOOACalls: true,
  SendEmailsWhenFCPOrdersAreCanceled: true,
  SearchType: 'COC',
  ServiceRequestContactDefaultEmail: 'loggedInUser',
  ServiceRequestContactDefaultName: 'loggedInUser',
  ServiceRequestContactDefaultPhone: 'loggedInUser'
}

export const LinkCustomer = () => {

  //Used to get warehouses per tenant
  const tenantName = useSelector(
    (states: RootState) => states.tenantState.tenantName,
  );

  //The user using the application
  const user = storage.getJSONItem(AdminStorageKey.userInfo) as AdminUser;
  const isSuperAdminUser = user.Tokens.indexOf(AdminToken.MANAGE_SUPER_ADMIN_USER) > -1;

  //params that exist if we are editing an existing customer
  const { id, addCustomerId } = useParams();
  const isEdit = !!id;

  //load the data for the customer we're working with, this can probably be cached at some point most of these endpoints will
  //always return the same data
  const { data: warehouseData } = useFlexLayer('/warehouses');
  const { data: quoteData } = useFlexLayer(`/quotes/${addCustomerId || id}`);
  const { data: globalSettings } = useFlexLayer('/globalSettings');
  const { data: problems, flexLayer } = useFlexLayer('/services/problems');

  let { data: customer, loading: customerLoading } : 
  { data: CustomerOptions, loading: boolean } = useFlexLayer(`/customers/${addCustomerId || id}`);

  // Modified customer is the form data being stored whenever a form value changes
  // used to handle some visual logic to hide/show fields
  const [ modifiedCustomerData, setModifiedCustomerData ] = useState<any>({});

  // To show loading state when submitting the form
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  //We have global settings that can be used to determine if we should show the extended notes field for all customers
  const isShowExtendNotes = globalSettings?.EnableCommunicationFromCustomersToZendesk;

  //If a customer is transactional we need to show the quotes they can pick from
  const isCustomerTransactional = modifiedCustomerData?.SearchType?.value[0]?.value?.includes('TR') ?? false;

  //unsure why this is a drop down select, this will never beable to change
  const defaultWareHouseData = useMemo(() => warehouseData?.find((warehouse) => {
    if(tenantName === Tenant.MARIMON && warehouse.WarehouseID === 131) return true; // Houston
    if(warehouse.WarehouseID === 803) return true; // FPI Drop Ship
  }), [warehouseData]);

  //Getting warehouses from the backend then populating them like this makes little sense but I'll keep it as is for now.
  const defaultWareHouse = [{
    label: defaultWareHouseData?.Warehouse,
    value: defaultWareHouseData?.WarehouseID,
  }]

  //quote data used to populate drop down
  const QuotesData = useMemo(() => quoteData?.map((QuotesObj: any) => {
    return {
      name: `QuoteID-${QuotesObj?.QuoteID}`,
      value: QuotesObj?.QuoteID,
      label: QuotesObj?.QuoteNumber,
    };
  }), [quoteData]);

  //problems data used to populate drop down
  const problemsData = useMemo(() => problems?.map((problemsObj: any) => {
    return {
      value: problemsObj?.ProblemCodeID,
      label: problemsObj?.Description,
    };
  }), [problems]);

  const navigate = useNavigate();
  const ref: FormRef = useRef(null);

 useMemo(() => {
  if(addCustomerId) {
    customer = {
      CustomerData: {
          ...customer?.CustomerData,
          ...defaultNewCustomerData,
          WarehouseID: tenantName === Tenant.MARIMON ? '131' : '803',
      },
      HideField: defaultHideMetaForNewCustomer,
      RequiredField: {},
      AuthZeroSettings: customer?.AuthZeroSettings
    }
  }
}, [addCustomerId, customer]);

  const checkoutHideFields: FormDefinition[] = [
    {
      name: 'hide poNumber',
      label: 'PONumber',
      type: FormType.CHECKBOX,
      disabled: modifiedCustomerData['meta poNumber']?.value,
      value: modifiedCustomerData['meta poNumber']?.value ? false : null,
    },
    {
      name: 'hide orderPhone',
      label: 'Phone',
      type: FormType.CHECKBOX,
      disabled: modifiedCustomerData['meta orderPhone']?.value,
      value: modifiedCustomerData['meta orderPhone']?.value ? false : null,
    },
    {
      name: 'hide cc',
      label: 'CC email',
      type: FormType.CHECKBOX,
      disabled: modifiedCustomerData['meta cc']?.value,
      value: modifiedCustomerData['meta cc']?.value ? false : null,
    },
    {
      name: 'hide department',
      label: 'Department',
      type: FormType.CHECKBOX,
      disabled: modifiedCustomerData['meta department']?.value,
      value: modifiedCustomerData['meta department']?.value ? false : null,
    },
    {
      name: 'hide floor',
      label: 'Floor',
      type: FormType.CHECKBOX,
      disabled: modifiedCustomerData['meta floor']?.value,
      value: modifiedCustomerData['meta floor']?.value ? false : null,
    },
  ];

  const [isServicesRequestAllow, setIsServicesRequestAllow] = useState(false);


  const requestPayloadForAddEdit = (customerData: any) => {
    //We convert the hide fields to a more usable format that the backend expects
    const hidefieldMeta = Object.keys(customerData).reduce((acc, key) => {
      let [prefix, name] = key.split(' ');
      if (prefix === 'hide') {
        acc[name] = customerData[key];
        delete customerData[key];
        delete customerData[name];
      }
      return acc;
    }, {});

    const fieldMeta = Object.keys(customerData).reduce((acc, key) => {
      let [prefix, name] = key.split(' ');
      if (prefix === 'meta') {
        acc[name] = customerData[key];
        delete customerData[key];
        delete customerData[name];
      }
      return acc;
    }, {});
    
    //All 'newUserDefaults are to be sent with authZero config
    const newUserDefaults: any = Object.keys(customerData).reduce((acc, key) => {
      if(key.includes('authZero')) {
        acc[key.split(' ')[1]] = customerData[key];
        delete customerData[key];
      }
      return acc;
    }, {});

    return {
      ...customer.CustomerData,
      fieldMeta,
      hidefieldMeta,
      ...customerData,
      Id: addCustomerId || id,
      WarehouseID: defaultWareHouse ? defaultWareHouse[0].value : null,
      WarehouseName: defaultWareHouse ? defaultWareHouse[0].label : null,
      SearchType: customerData.SearchType[0],
      ServiceRequestContactDefaultEmail: customerData.ServiceRequestContactDefaultEmail[0],
      ServiceRequestContactDefaultName: customerData.ServiceRequestContactDefaultName[0],
      ServiceRequestContactDefaultPhone: customerData.ServiceRequestContactDefaultPhone[0],
      emailDomains: customerData.emailDomains?.split(',') || [],
      AuthZeroConfig: {
        newUserDefaults: {
          PlaceOrderAddressID: [],
          ServiceRequestAddressID: [],
          RestrictUserByLocationID: [],
          ...newUserDefaults,
        },
      },
    };
  };

  const onSubmitHandler = async (customerData: any) => {
      setIsSubmitting(true);
      const payload = requestPayloadForAddEdit(customerData);
      const body = !id ? { customerData: payload } : payload;

      const { error } = await flexLayer(
        `/customers${id ? `/${id}` : ''}`,
        body,
        { method: id ? 'PUT' : 'POST' });

      setIsSubmitting(false);

      if(error) {
        return showMessage({ type: 'error', message: `Something went wrong when attempting to ${id ? 'update' : 'add'} this customer` });
      } else {
        showMessage({ type: 'success', message: `Successfully ${id ? 'updated' : 'added'} this customer` });
        return navigate('/admin/customer');
      }
  }

  const onCancelHandler = () => {
    navigate('/admin/customer');
  }

  const changeFormValue = (changedValue: any) => {
    setModifiedCustomerData({...changedValue})
  }

  //We set the default values for the form based on the customer data we have
  let defaultValues = useMemo(() => {
    if (!customer) return {};

    //convert customer HideField to 'hide fieldName' format
    const hideField = Object.keys(customer.HideField).reduce((acc, key) => {
      acc[`hide ${key}`] = customer.HideField[key];
      return acc;
    }, {});

    //convert customer RequiredField to 'meta fieldName' format
    const requiredField = Object.keys(customer.RequiredField).reduce((acc, key) => {
      acc[`meta ${key}`] = customer.RequiredField[key];
      return acc;
    }, {});

    return {
        ...customer.CustomerData,
        ...hideField,
        ...requiredField,
        ServiceRequestContactDefaultEmail: [contactOptions.find(option => option.value === customer.CustomerData.ServiceRequestContactDefaultEmail) || contactOptions[0]],
        ServiceRequestContactDefaultName: [contactOptions.find(option => option.value === customer.CustomerData.ServiceRequestContactDefaultName) || contactOptions[0]],
        ServiceRequestContactDefaultPhone: [contactOptions.find(option => option.value === customer.CustomerData.ServiceRequestContactDefaultPhone) || contactOptions[0]],
        SearchType: [searchTypeOptions.find(option => option.value === customer.CustomerData.SearchType) || searchTypeOptions[1]],
        enableAuthZero: !!customer?.AuthZeroSettings,
        isAuthZeroSSO: !!customer?.AuthZeroSettings?.allowAutoCreateNewUser,
        emailDomains: customer?.AuthZeroSettings?.emailDomains?.join(','),
        allowAutoCreateNewUser: customer?.AuthZeroSettings?.allowAutoCreateNewUser,
        'authZero Tokens': UserRoles.filter(role => customer?.AuthZeroSettings?.newUserDefaults?.Tokens?.includes(role.value)),
        'authZero RestrictUserByLocationID': customer?.AuthZeroSettings?.newUserDefaults?.RestrictUserByLocationID,
        'authZero PlaceOrderAddressID': customer?.AuthZeroSettings?.newUserDefaults?.PlaceOrderAddressID,
        'authZero ServiceRequestAddressID': customer?.AuthZeroSettings?.newUserDefaults?.ServiceRequestAddressID,
        'authZero CConNonWebOrders': customer?.AuthZeroSettings?.newUserDefaults?.CConNonWebOrders,
        'authZero HideOtherUserOrders': customer?.AuthZeroSettings?.newUserDefaults?.HideOtherUserOrders,
    }
  }, [customer]);


  console.log({defaultValues})


  if(customerLoading) {
    return <ComponentLoading isShowLoader={true} />
  }

  return (
    <>
      {defaultWareHouse && customer ? (
        <div className="h-full w-full">
          <h2 className="font-semibold text-gray-900 mb-8">
            {id
              ? `Edit Customer - ${customer.CustomerData.CustomerName}`
              : `Add Customer - ${customer.CustomerData.CustomerName}`}
          </h2>
          <div>
            <div className="addCustomerForm">
              <Form
                formRef={ref}
                defaultValues={defaultValues}
                onSubmit={onSubmitHandler}
                onChange={changeFormValue}
              >

                <div className="sm:w-1/2 mb-6 labelStyle">
                  <FormItems
                    items={[
                      {
                        name: 'SearchType',
                        label: 'Item Availability',
                        selectShowCheckboxes: false,
                        selectHideSearch: true,
                        ClearSelectedIcon: null,
                        type: FormType.SELECT,
                        required: true,
                        options: searchTypeOptions,
                      },
                    ]}
                  />
                  { isCustomerTransactional && <div className={`mb-6 quotesBar`}>
                    <label className="font-medium mb-1 block text-gray-900 text-sm">
                      Quotes <span className="text-red-400">*</span>{' '}
                    </label>
                    <div>
                      <FormItem
                        formDef={{
                          name: 'QuotesID',
                          label: 'Select All Quotes',
                          optionsMultiSelect: true,
                          selectAll: true,
                          required: true,
                          selectAllLabel: 'Select All Quotes',
                          type: FormType.CHECKBOX,
                          radioOptions: QuotesData,
                          errors: {
                            required: 'Please select atleast one quote.',
                          },
                        }}
                      />
                    </div>
                  </div> }
                </div>

                <div className="sm:my-12 sm:pt-8 sm:pb-8 sm:border-y border-gray-300">
                <div className="mb-10">
                      <label className="text-lg mb-1 block text-gray-900 font-medium">
                        General Settings
                      </label>
                      <p className="text-sm font-normal text-gray-400">
                        These settings are used to control the behavior of the
                        customer portal, grayed out settings must be changed via a request to <a className="text-red-400" href="mailto:ITSupport@flextg.com">ITSupport@flextg.com</a>.
                      </p>
                    </div>
                  <div className="grid lg:grid-cols-4 lg:gap-8 md:grid-cols-3 sm:grid-cols-2 gap-4 mb-6">
                    <GeneralSetting
                      label='Pricing' 
                      formDef={{
                        name: 'HideZeroDollarPricing',
                        label: 'Hide $0 pricing',
                        type: FormType.CHECKBOX,
                      }}
                      tooltip='When enabled, any place that would show $0 (checkout, cart, etc.) will show nothing.'
                    />
                    <GeneralSetting
                      label='Hide All Equipment Orders' 
                      formDef={{
                        name: 'HideEquipmentOrders',
                        label: 'Hide all equipment orders',
                        type: FormType.CHECKBOX,
                      }}
                      tooltip='When enabled, users will not be able to see equipment orders (ex: printers).'
                    />
                    <GeneralSetting
                      label='Allow extended location remarks' 
                      formDef={{
                        name: 'LongerLocationRemarks',
                        label: 'Up to 80 characters for Supply Routing Labels',
                        type: FormType.CHECKBOX,
                        disabled: !isSuperAdminUser,
                      }}
                      tooltip='When enabled, location remarks can be up to 80 characters long. This is typically only used for customers that need longer remarks for supply routing labels.'
                    />
                    <GeneralSetting
                      label='Shipping & Handling' 
                      formDef={{
                        name: 'ExemptFromFreightOnTransactionalOrders',
                        label:
                          'Exempt from freight on transactional orders',
                        type: FormType.CHECKBOX,
                        disabled: !isSuperAdminUser,
                      }}
                      tooltip='When enabled, customers will not have to pay shipping costs on transactional orders.'
                    />

                    <GeneralSetting
                      label="Region Codes"
                      formDef={[
                        {
                          name: 'AllowRestrictRegion',
                          label: 'Allow restricting users by region',
                          type: FormType.CHECKBOX,
                        },
                        {
                          name: 'ShowRegionCodeInGrid',
                          label: 'Show region code on location grid &reports',
                          type: FormType.CHECKBOX,
                        },
                      ]}
                      tooltip='If a customer requests to have regions (such as states) we can enable these rules for them. To work properly, locations must have the "Region" custom property filled out in EA.'
                    />
                    <GeneralSetting
                      label='View reports' 
                      formDef={{
                        name: 'AllowReportView',
                        label: 'Can view reports',
                        type: FormType.CHECKBOX,
                      }}
                      tooltip='When enabled, a new tab on the customer portal appears that allows the customer to view reporting pages.'
                    />
                    { tenantName !== Tenant.MARIMON && <GeneralSetting
                      label='Show Device Lease Expiration Date' 
                      formDef={{
                        name: 'ShowContractExpirationDate',
                        label: 'Show device lease expiration date',
                        type: FormType.CHECKBOX,
                        disabled: !isSuperAdminUser,
                      }}
                      tooltip='When enabled, on the device detail page, we will show a customer their lease expiration date. This should only be enabled where required by contract, due to exposing potentially sensitive information.'
                    /> }
                    <GeneralSetting
                      label="Cost Center/ GL Code"
                      formDef={{
                        name: 'CanViewGLCode',
                        label: 'Can view cost center / GL code',
                        type: FormType.CHECKBOX,
                      }}
                      tooltip='When enabled, customers will be able to see cost center and GL codes on grids and pages from the associated custom property in EA.'
                    />
                    <GeneralSetting
                      label='Attention line' 
                      formDef={[
                        {
                          name: 'CanAutoFillAttentionField',
                          label:
                            'Auto fill attn with location remark and equipment number',
                          type: FormType.CHECKBOX,
                        },
                        {
                          name: 'CanPrependPONumberAttentionField',
                          label: 'Prepend PO#/Cost Center to attn line',
                          type: FormType.CHECKBOX,
                        },
                      ]}
                      tooltip='When enabled, the attention line will be automatically filled with location remark and equipment number.'
                    />
                    <GeneralSetting
                      label='Service Request Number' 
                      formDef={[
                        {
                          name: 'AllowServiceRequestNumber',
                          label:
                            'Allow customer to enter their service request number',
                          type: FormType.CHECKBOX,
                          onChange: () => {
                            ref?.clearInputs(['Prefix']);
                            ref?.removeFormDef(['Prefix']);
                            setIsServicesRequestAllow(
                              !isServicesRequestAllow,
                            );
                          }
                        },
                        {
                          name: 'Prefix',
                          label: 'Customer prefix',
                          type: FormType.TEXT,
                          required: isServicesRequestAllow,
                          minLength: 3,
                          maxLength: 3,
                          className: isServicesRequestAllow ? '' : 'hidden',
                        },
                      ]}
                      tooltip='When enabled, customers will enter their own service request number, with a fixed prefix. This is typically only used if the customer wants to align their internal ticket # with our own.'
                    />
                    { tenantName !== Tenant.MARIMON && <GeneralSetting
                      label='Send service calls to the Help Desk for triage'
                      formDef={[
                        {
                          name: 'TriageW2Calls',
                          label: 'Triage W2 Calls',
                          type: FormType.CHECKBOX,
                          disabled: !isSuperAdminUser,
                        },
                        {
                          name: 'TriageOOACalls',
                          label: 'Triage OOA Calls',
                          type: FormType.CHECKBOX,
                        },
                      ]}
                      tooltip='When enabled, service calls will be sent to the help desk for triage, based on what type of technician the call would be assigned to.'
                    /> }
                    
                    <GeneralSetting
                      label='Allow hiding orders by user' 
                      formDef={{
                        name: 'AllowHidingUsersWebOrders',
                        label: 'Allow hiding orders by user',
                        type: FormType.CHECKBOX,
                      }}
                      tooltip='When enabled, users of this customer will be able to hide auto toner, phone, and other users web orders. This is used rarely, by customers that need strict security around who can view each others supply orders.'
                    />
                    { problemsData && <GeneralSetting
                      label='Available Primary Symptoms (leave blank for all)'
                      formDef={{
                        name: 'PrimarySymptom',
                        label: '',
                        type: FormType.SELECT,
                        ClearSelectedIcon: null,
                        options: problemsData,
                        optionsMultiSelect: true,
                        selectAllLabel: 'Select All',
                        selectShowSelectAll: true,
                        selectShowChip: true,
                        selectMultiSelect: true,
                        selectShowSelectAllFilter: true,
                      }}
                      tooltip='When selected, customers will be able to select from a list of primary symptoms when requesting service, if left blank they will have access to all.'
                    /> }
                    { tenantName !== Tenant.MARIMON && <GeneralSetting
                      label='Service Note'
                      formDef={{
                        name: 'Note',
                        label:
                          ' Service Note (will be added to all calls, visible to customer)',
                        type: FormType.TEXTAREA,
                        maxLength: 80,
                        disabled: !isSuperAdminUser,
                      }}
                      tooltip='When filled out, this note will be added to all calls and will be visible to the customer. Reach out to our Service team to get this set up.'
                    /> }
                    <GeneralSetting
                      label="Warn customer of potential duplicate orders"
                      formDef={{
                        name: 'WarnCustomerOfPotentialDuplicateOrders',
                        label: 'Enable',
                        type: FormType.CHECKBOX,
                      }}
                      tooltip='When enabled, when a customer attempts to add an item to their cart or place an order, a pop up will warn them of a potential duplicate order.'
                    />
                    <GeneralSetting
                      label="Send emails when FCP orders are canceled"
                      formDef={{
                        name: 'SendEmailsWhenFCPOrdersAreCanceled',
                        label: 'Enable',
                        type: FormType.CHECKBOX,
                      }}
                      tooltip='When enabled, if for whatever reason an order is canceled, the customer will be sent an email.'
                    />
                  </div>
                </div>

                <div className="mb-8 border-b border-gray-300 sm:pb-8 pb-4">
                  <div className="mb-3">
                    <label className="font-medium mb-1 block text-gray-900 text-sm">
                      Service Request Contact Defaults
                    </label>
                  </div>
                  <div className="grid sm:grid-cols-3 sm:gap-6 labelStyle">
                    {/* Service Request Contact Defaults */}

                    {
                      <div>
                        <FormItems
                          items={[
                            {
                              name: 'ServiceRequestContactDefaultName',
                              label: 'Default Name',
                              type: FormType.SELECT,
                              ClearSelectedIcon: null,
                              selectShowCheckboxes: false,
                              selectHideSearch: true,
                              required: true,
                              options: contactOptions,
                            },
                          ]}
                        />
                      </div>
                    }
                    <FormItems
                      items={[
                        {
                          name: 'ServiceRequestContactDefaultPhone',
                          label: 'Default Phone',
                          type: FormType.SELECT,
                          required: true,
                          ClearSelectedIcon: null,
                          selectShowCheckboxes: false,
                          selectHideSearch: true,
                          options: contactOptions,
                        },
                      ]}
                    />
                    <FormItems
                      items={[
                        {
                          name: 'ServiceRequestContactDefaultEmail',
                          label: 'Default Email',
                          type: FormType.SELECT,
                          required: true,
                          ClearSelectedIcon: null,
                          selectShowCheckboxes: false,
                          selectHideSearch: true,
                          options: contactOptions,
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="grid sm:grid-cols-3 sm:gap-6 gap-2">
                  <div>
                    {/* Checkout Page Require Field */}
                    <div>
                      <label className="font-medium mb-1 block text-gray-900 text-sm">
                        Checkout Page Require Field
                      </label>
                      <FormItems items={checkoutRequireFields} />
                    </div>
                  </div>
                  <div>
                    {/* Service Request Require Field  */}
                    <div>
                      <label className="font-medium mb-1 block text-gray-900 text-sm">
                        Service Request Require Field
                      </label>
                      <FormItems
                        items={
                          isShowExtendNotes
                            ? [
                                ...serviceRequestRequireFields,
                                {
                                  name: 'extendedNotes',
                                  label: 'Extended Notes',
                                  type: FormType.CHECKBOX,
                                },
                              ]
                            : [...serviceRequestRequireFields]
                        }
                      />
                    </div>
                  </div>
                  <div>
                    {/* Checkout Page Hide Field */}
                    <div>
                      <label className="font-medium mb-1 block text-gray-900 text-sm">
                        Checkout Page Hide Field
                      </label>
                      <FormItems items={checkoutHideFields} />
                    </div>
                  </div>
                </div>
                { (user.Tokens.indexOf(AdminToken.MANAGE_SUPER_ADMIN_USER) > -1 && isEdit) && <div className="mt-4 w-full border-t-gray-300 border-t pt-4">
                
                <div className="mb-3">
                  <label className="font-medium mb-1 block text-gray-900 text-sm">
                    AuthZero Settings
                  </label>
                </div>

                <div>
                  <FormItem
                    className='customInput'
                      formDef={{
                        name: 'enableAuthZero',
                        label: 'Enable Auth Zero for this customer',
                        value: !!modifiedCustomerData?.AuthZeroSettings,
                        type: FormType.CHECKBOX,
                      }}
                    />
                </div>

                { modifiedCustomerData?.enableAuthZero?.value && <div className="grid sm:grid-cols-3 sm:gap-6 labelStyle py-4">
                  <div>
                    <FormItems
                      items={[
                        {
                          name: 'isAuthZeroSSO',
                          label: 'Enable Auth Zero SSO',
                          type: FormType.CHECKBOX,
                        }
                      ]}
                    />
                    </div>
                      
                  </div>}

                  { modifiedCustomerData?.isAuthZeroSSO?.value && <div>
                      <FormItems
                        items={[
                          {
                            name: 'emailDomains',
                            label: <span>
                              <label className="font-medium block text-gray-900 text-sm">
                                Auth Zero Domain
                              </label>
                              <span className="text-xs text-gray-400">Any email with this domain will be auto redirected to the customers SSO platform</span>
                            </span>,
                            type: FormType.TEXT,
                            required: modifiedCustomerData.isAuthZeroSSO.value,
                            errors: {
                              required: 'Please enter a valid domain'
                            }
                          },                         
                          {
                            name: 'allowAutoCreateNewUser',
                            label: 'Allow new user creation',
                            helperText: 'When enabled, users who exist in the customer SSO can automatically create an account on the portal.',
                            type: FormType.CHECKBOX,
                            className: 'my-4'
                          },
                        ]}
                        />
                  </div>}

                  { modifiedCustomerData?.allowAutoCreateNewUser?.value && <>

                  <div className="mb-4">
                    <label className="font-medium mb-1 block text-gray-900 text-sm">
                      New user default settings
                    </label>
                    <div className="text-xs text-stone-400">
                      These settings are used to control the default permissions and settings for new users created via SSO.
                    </div>
                  </div>
                  
                  <div className="grid lg:grid-cols-4 gap-3">
                    <FormItem
                      formDef={{
                        name: 'authZero Tokens',
                        label: 'Default assigned permissions.',
                        type: FormType.SELECT,
                        required: modifiedCustomerData.allowAutoCreateNewUser.value,
                        selectMultiSelect: true,
                        options: UserRoles,
                        isMenuTop: true,
                      }}
                      />

                      <AuthZeroSettings defaultValues={defaultValues} isRestrictRegion={modifiedCustomerData.AllowRestrictRegion?.value} id={id || addCustomerId} />
                  </div></>}

                </div> }

                <div className="flex justify-end py-6 border-t border-gray-200  mt-6">
                  <button
                    type="button"
                    className={`borderedBtn mr-2 linkText hover:text-black`}
                    onClick={() => {
                      onCancelHandler();
                    }}
                  >
                    Cancel
                  </button>
                  <SubmitBtn className="commonBtn" loading={isSubmitting}>
                    {id ? 'Update' : 'Create'}
                  </SubmitBtn>
                </div>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};


