import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../../shared/api/api';
import {
  colorCoder,
  getTime,
  isCheckDollerProcingNonZero,
  renderUSCurrency,
} from '../../../../utils/utils';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import storage from '../../../../utils/storage/storage';
import { ClientStorageKey, TENANT } from '../../../../constant/constant';
import { PushpinIcon } from '../../../../assets/svg/pushpin-icon';
import { LocationContactIcon } from '../../../../assets/svg/location-contact-icon';
import { BuildingIcon } from '../../../../assets/svg/building-icon';
import { ProgressStatusBar } from '../../../../shared/progress-status-bar/progress-status-bar';
import { RootState } from '../../../../store/store';
import { Chip } from '../../../../components/ui/Chip';
import { ComponentLoading } from '../../../../shared/component-loader/component-loading';
import { useUser } from '../../../../context/UserContext';
import { CustomerTokens } from '../../../../types/Customer';
import { useCart } from '../../context/CartContext';

export const OrderDetail = () => {
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );
  let { SOID } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [IsOnlyReturnItem, setIsOnlyReturnItem] = useState(false);
  let [totalCost, setTotalCost] = useState(0);
  let [cancelTotalCost, setCancelTotalCost] = useState(0);
  let [returnTotalCost, setReturnTotalCost] = useState(0);
  const [trackingInfoDisplay, setTrackingInfoDisplay] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const { customerInfo, hasPermission } = useUser();
  const { addToCart } = useCart();

  const [stepValues] = useState([
    {
      name: 'Ordered',
      isActive: false,
    },
    {
      name: 'Shipped',
      isActive: false,
    },
    {
      name: 'Out for delivery',
      isActive: false,
    },
    {
      name: 'Delivered',
      isActive: false,
    },
  ]);

  const getOrderDetails = async () => {
    try {
      const queryObj = {
        UserId: '',
        AllowHidingUsersWebOrders: false,
        CustomerId: '',
        SOID: '',
        EmailAddress: '',
        LocationID: null,
      };

      queryObj.UserId = customerInfo.UserId;
      queryObj.AllowHidingUsersWebOrders =
        customerInfo.AllowHidingUsersWebOrders;
      queryObj.CustomerId = customerInfo.CustomerId;
      queryObj.SOID = SOID;
      queryObj.EmailAddress = customerInfo.EmailAddress;
      queryObj.LocationID =
        customerInfo.RestrictUserByLocationID &&
        customerInfo.RestrictUserByLocationID.length
          ? customerInfo.RestrictUserByLocationID.toString()
          : null;

      const response = await Api.callAPI({
        url: `/api/v1/orders/${SOID}`,
        method: 'PUT',
        body: queryObj,
        options: {
          successMessage: false,
          errorMessage: 'Error while getting order details.',
        },
      });
      if (response.data.OrderDetail && response.data.ShipmentData) {
        const orderDetailObj = response?.data?.OrderDetail;
        const ShipmentDataObj = response?.data?.ShipmentData;

        if (
          orderDetailObj.OrderItems.length < 1 &&
          orderDetailObj.CanceledItems.length < 1
        ) {
          orderDetailObj.Status = 'Returned';
          orderDetailObj.StatusClass = 'onhold';
          setIsOnlyReturnItem(true);
        }
        orderDetailObj.CreatedDate = getTime(orderDetailObj.CreatedDate);
        let totalCostValue = 0;

        ShipmentDataObj.forEach((shipmentValueObj) => {
          shipmentValueObj.TrackingOrders.forEach((item) => {
            const price = parseFloat(item.Price);
            item.Price = price.toFixed(2);
            const quantity = item.Quantity;
            if (tenantName === TENANT.MARIMON) {
              item.tonerClass =
                (item &&
                  item.ItemDescription &&
                  colorCoder(item.ItemDescription)) ||
                null;
            } else {
              item.tonerClass =
                (item &&
                  item.CatDescription &&
                  colorCoder(item.CatDescription)) ||
                null;
            }

            item.totalPriceOfOneProduct = price * item.Quantity;
            totalCostValue += price * quantity;
          });
        });

        setTotalCost(totalCostValue);

        _.each(orderDetailObj.CanceledItems, (item) => {
          const price = parseFloat(item.Price);
          item.Price = price.toFixed(2);
          const quantity = item.Quantity;
          if (tenantName === TENANT.MARIMON) {
            item.tonerClass =
              (item &&
                item.ItemDescription &&
                colorCoder(item.ItemDescription)) ||
              null;
          } else {
            item.tonerClass =
              (item &&
                item.CatDescription &&
                colorCoder(item.CatDescription)) ||
              null;
          }
          item.totalPriceOfOneProduct = price * item.Quantity;

          setCancelTotalCost((cancelTotalCost += price * quantity));
        });

        let returnCostValue = 0;
        _.each(orderDetailObj.ReturnItems, (item) => {
          const price = parseFloat(item.Price);
          item.Price = price.toFixed(2);
          const quantity = item.Quantity;
          if (tenantName === TENANT.MARIMON) {
            item.tonerClass =
              (item &&
                item.ItemDescription &&
                colorCoder(item.ItemDescription)) ||
              null;
          } else {
            item.tonerClass =
              (item &&
                item.CatDescription &&
                colorCoder(item.CatDescription)) ||
              null;
          }
          item.totalPriceOfOneProduct = price * item.Quantity;
          returnCostValue += price * quantity;
        });
        setReturnTotalCost(returnCostValue);

        const shipmentDetailObj = response.data.ShipmentData;
        if (shipmentDetailObj?.length) {
          _.each(shipmentDetailObj, (shipment) => {
            if (
              !(
                shipment.TrackingNumbers === '' ||
                shipment.TrackingNumbers === null
              )
            ) {
              setTrackingInfoDisplay(true);
            }
            if (shipment.TrackingNumbers === null) {
              shipment.TrackingNumbers = [''];
            }
          });
        }

        stepValues?.forEach((value) => {
          if (response?.data?.ShipmentData[0]?.TrackingStatus === 'P') {
            if (value.name === 'Ordered') {
              value.isActive = true;
            }
          } else if (response?.data?.ShipmentData[0]?.TrackingStatus === 'S') {
            if (value.name === 'Ordered' || value.name === 'Shipped') {
              value.isActive = true;
            }
          } else if (response.data.ShipmentData[0]?.TrackingStatus === 'OD') {
            if (
              value.name === 'Ordered' ||
              value.name === 'Shipped' ||
              value.name === 'Out for delivery'
            ) {
              value.isActive = true;
            }
          } else if (response?.data?.ShipmentData[0]?.TrackingStatus === 'DL') {
            if (
              value.name === 'Ordered' ||
              value.name === 'Shipped' ||
              value.name === 'Delivered' ||
              value.name === 'Out for delivery'
            ) {
              value.isActive = true;
            }
          }
        });

        setOrderDetailsData({
          orderDetailObj: orderDetailObj,
          shipmentDetailObj: shipmentDetailObj,
        });
      }
    } catch (e) {
      navigate('/customer/order-history');
    }
  }

  const onHandlerOrderAgain = async (orderDetails, cancelOrderDetails) => {
    const itemObj = [...orderDetails, ...cancelOrderDetails];
    await addToCart(itemObj);
  };

  useEffect(() => {
    getOrderDetails().then();
  }, []);

  useEffect(() => {
    const obj = {
      timeRange: parseInt(query.get('timeRange')),
      orderNumber: query.get('orderNumber'),
    };
    storage.setJSONItem(ClientStorageKey.OrderFilter, obj);
  }, []);
  const IsPriceHeaderShow = (OrderItems: any) => {
    if (customerInfo?.HideZeroDollarPricing) {
      let flag = false;
      OrderItems?.map((item: any) => {
        if (parseInt(item.Price) > 0) {
          flag = true;
        }
      });
      return flag;
    } else {
      return true;
    }
  };
  const IsTotalHeaderShow = (OrderItems: any) => {
    if (customerInfo?.HideZeroDollarPricing) {
      let flag = false;
      OrderItems?.map((item: any) => {
        if (parseInt(item.totalPriceOfOneProduct) > 0) {
          flag = true;
        }
      });
      return flag;
    } else {
      return true;
    }
  };
  return (
    <>
      {orderDetailsData && orderDetailsData?.orderDetailObj ? (
        <div>
          <div className="mb-4 md:flex justify-between">
            <div className={'leftSideBox 2xl:w-4/5 md:w-4/6 '}>
              <div className="flex lg:items-center mb-5">
                <h1 className="lg:text-2xl text-xl font-medium">
                  Order Details
                  {orderDetailsData?.orderDetailObj?.SOCode
                    ? ' - ' + orderDetailsData?.orderDetailObj?.SOCode
                    : ''}
                </h1>
                {orderDetailsData?.orderDetailObj?.SOCode && (
                  <div className="ml-4">
                    <Chip status={orderDetailsData?.orderDetailObj?.Status} />
                  </div>
                )}
              </div>
              <div>
                <div className="text-sm">
                  {orderDetailsData?.orderDetailObj?.PONumber && (
                    <div className="mb-1">
                      <span className="text-gray-600">PO#:</span>
                      <span className="text-gray-800 ml-1">
                        {orderDetailsData?.orderDetailObj?.PONumber}
                      </span>
                    </div>
                  )}

                  {orderDetailsData?.orderDetailObj?.OrderType && (
                    <div className="mb-1">
                      <span className="text-gray-600">Order type:</span>
                      <span className="text-gray-800 ml-1">
                        {orderDetailsData?.orderDetailObj?.OrderType}
                      </span>
                    </div>
                  )}

                  {orderDetailsData?.orderDetailObj
                    ?.OrderCreatedEmailAddress && (
                    <div className="mb-1">
                      <span className="text-gray-600">Entered on Web By:</span>
                      <span className="text-gray-800 ml-1">
                        {
                          orderDetailsData?.orderDetailObj
                            ?.OrderCreatedEmailAddress
                        }
                      </span>
                    </div>
                  )}

                  <div>
                    {orderDetailsData?.orderDetailObj?.Remarks &&
                    orderDetailsData?.orderDetailObj?.Remarks !== '' ? (
                      <div className="mb-1">
                        <span className="text-gray-600">Notes:</span>

                        {orderDetailsData?.orderDetailObj?.Remarks?.length >
                        100 ? (
                          <span className="text-gray-800 ml-1">
                            {isReadMore ? (
                              <>{orderDetailsData?.orderDetailObj?.Remarks}</>
                            ) : (
                              <>
                                {orderDetailsData?.orderDetailObj?.Remarks.slice(
                                  0,
                                  100,
                                )}
                              </>
                            )}
                            <button
                              onClick={() => setIsReadMore(!isReadMore)}
                              className="cursor-pointer linkText ml-2 font-medium"
                            >
                              {isReadMore ? 'Read Less' : 'Read More'}
                            </button>
                          </span>
                        ) : (
                          <span className="text-gray-800 ml-1">
                            <>{orderDetailsData?.orderDetailObj?.Remarks}</>
                          </span>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="rightSideBox 2xl:w-1/5  md:w-2/6 md:flex flex-col items-end sm:pt-0 pt-4 sm:pb-0 pb-4">
              {orderDetailsData?.orderDetailObj?.CreatedDate && (
                <div className="text-sm md:text-right font-medium md:mt-0 mt-4">
                  <div className="text-gray-600">Ordered On:</div>
                  <div className="text-gray-800 text-xl">
                    {orderDetailsData?.orderDetailObj?.CreatedDate}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <div>
            {orderDetailsData?.orderDetailObj?.Remarks &&
            orderDetailsData?.orderDetailObj?.Remarks !== '' ? (
              <div className='mt-6 mb-10 flex'>
                <div className='font-medium mr-2'>Notes -</div>
                <div className='text-sm text-gray-700 text-ellipsis overflow-hidden whitespace-nowrap w-80 mr-2'>{orderDetailsData?.orderDetailObj?.Remarks}</div>
                <a className='linkText'>Read More</a>
              </div>
            ) : (
              <></>
            )}
          </div> */}

          <div className="sm:flex lg:flex-nowrap flex-wrap lg:gap-4 gap-0 my-6">
            <div className="lg:w-1/3 sm:w-1/2 lg:mb-0 mb-4 md:pr-4 ">
              <div className="flex items-center lg:mb-4 mb-2">
                <PushpinIcon />
                <div className="ml-2 text-base font-semibold">
                  Shipping Details
                </div>
              </div>
              <div className="ml-7 text-sm">
                <div>
                  {orderDetailsData?.orderDetailObj?.ShipToName &&
                    orderDetailsData?.orderDetailObj?.ShipToName}
                  <br />
                  {orderDetailsData?.orderDetailObj?.ShipToATTN}
                  <br />
                  {orderDetailsData?.orderDetailObj?.ShipToStreet}
                  <br />
                  {orderDetailsData?.orderDetailObj?.ShipToCity},{' '}
                  {orderDetailsData?.orderDetailObj?.ShipToState},{' '}
                  {orderDetailsData?.orderDetailObj?.ShipToZip}
                  <br />
                  {orderDetailsData?.orderDetailObj?.ShipToCountry}
                </div>
              </div>
            </div>

            <div className="lg:w-1/3 sm:w-1/2 lg:mb-0 mb-4 md:pr-4 ">
              <div className="flex items-center lg:mb-4 mb-2">
                <BuildingIcon />
                <div className="ml-2 text-base font-semibold">
                  Company Information
                </div>
              </div>
              <div className="ml-7 text-sm">
                <div>
                  {customerInfo.CustomerName}
                  <br />
                  {customerInfo.CustomerAddress}
                  <br />
                  {customerInfo.CustomerCity}, {customerInfo.CustomerState},{' '}
                  {customerInfo.CustomerZip}
                  <br />
                  {customerInfo.CustomerCountry}
                </div>
              </div>
            </div>

            <div className="lg:w-1/3 sm:w-full lg:mb-0 mb-4 md:pr-4 ">
              {trackingInfoDisplay ? (
                <>
                  <div className="flex items-center lg:mb-4 mb-2">
                    <LocationContactIcon />
                    <div className="ml-2 text-base font-semibold">
                      Tracking Information
                    </div>
                  </div>
                  <div className="ml-7 text-sm">
                    <div className="lg:block grid md:grid-cols-4 sm:grid-cols-2 gap-4">
                      {orderDetailsData?.shipmentDetailObj &&
                        orderDetailsData?.shipmentDetailObj?.map(
                          (
                            shipmentDetailData: any,
                            shipmentDetailDataIndex: number,
                          ) => {
                            return (
                              <div
                                className="lg:mb-5 md:mb-0 mb-5 lg:mr-0 mr-2"
                                key={
                                  'shipmentDetailData' + shipmentDetailDataIndex
                                }
                              >
                                <p>Shipment #{shipmentDetailDataIndex + 1}</p>
                                <div>
                                  {shipmentDetailData.ShipMethod && (
                                    <div>{shipmentDetailData.ShipMethod}</div>
                                  )}
                                  {shipmentDetailData?.TrackingNumbers && (
                                    <Link
                                      to={`/customer/order-tracking/${shipmentDetailData?.TrackingNumbers}`}
                                      className="linkText"
                                    >
                                      {shipmentDetailData?.TrackingNumbers}
                                    </Link>
                                  )}
                                </div>
                              </div>
                            );
                          },
                        )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            {orderDetailsData.shipmentDetailObj &&
              orderDetailsData.shipmentDetailObj?.map(
                (ShipmentData: any, shipmentDetailObjIndex: number) => {
                  return (
                    <div key={'shipmentDetailObj' + shipmentDetailObjIndex}>
                      <div className="">
                        <div className="sm:flex lg:flex-nowrap flex-wrap lg:gap-8 gap-0 mt-6 mb-10">
                          <div className="sm:w-1/3 w-full md:mb-0 mb-6 md:pr-4">
                            <div className="flex items-center lg:mb-4 mb-2">
                              <LocationContactIcon />
                              <div className="ml-2 text-base font-semibold">
                                Shipment #{shipmentDetailObjIndex + 1}
                              </div>
                            </div>
                            <div className="ml-7 text-sm">
                              {ShipmentData.ShipMethod && (
                                <div>{ShipmentData.ShipMethod} </div>
                              )}

                              {ShipmentData?.TrackingNumbers && (
                                <div>{ShipmentData?.TrackingNumbers}</div>
                              )}

                              {!ShipmentData.TrackingNumbers && (
                                <div>No Tracking Available.</div>
                              )}

                              {ShipmentData?.ScheduleDelivery &&
                                ShipmentData?.ScheduleDelivery !== '' && (
                                  <div className="mt-4">
                                    <div className="font-medium text-base">
                                      Scheduled Delivery
                                    </div>
                                    <div className="text-xl">
                                      {ShipmentData?.ScheduleDelivery}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="sm:w-2/3 w-full sm:mb-0 mb-4 md:pr-4 md:pl-0 pl-2">
                            <ProgressStatusBar steps={stepValues} />
                          </div>
                        </div>

                        <div className="border border-gray-200">
                          <div className="md:flex hidden bg-gray-100 border-b border-gray-200 py-3 px-4">
                            <div className="xl:w-9/12 md:w-2/5 w-full">
                              Product
                            </div>
                            <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                              {ShipmentData?.TrackingOrders &&
                              IsPriceHeaderShow(
                                ShipmentData?.TrackingOrders,
                              ) ? (
                                <>Price</>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                              Quantity
                            </div>
                            <div className="xl:w-1/12 md:w-1/5  w-1/3 text-right">
                              {ShipmentData?.TrackingOrders &&
                              IsTotalHeaderShow(
                                ShipmentData?.TrackingOrders,
                              ) ? (
                                <>Total</>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="productListing">
                            <div className="orderProductListingBar [&>*:last-child]:border-0">
                              {ShipmentData?.TrackingOrders?.map(
                                (
                                  trackingData: any,
                                  TrackingOrdersIndex: number,
                                ) => {
                                  return (
                                    <div
                                      className="flex border-b border-gray-200 py-4 mb-4 px-4 flex-wrap"
                                      key={
                                        'TrackingOrdersIndex' +
                                        TrackingOrdersIndex
                                      }
                                    >
                                      <div className="xl:w-9/12 md:w-2/5 w-full  md:mb-0 mb-4">
                                        <div className="md:hidden block text-gray-500">
                                          Product
                                        </div>
                                        <div className="flex items-center">
                                          <div className="w-4 mr-2 ">
                                            {trackingData?.tonerClass ? (
                                              <div
                                                className="w-4 h-4 rounded-full ItemDescription mt-1"
                                                style={{
                                                  backgroundColor:
                                                    trackingData?.tonerClass,
                                                }}
                                              ></div>
                                            ) : (
                                              ''
                                            )}
                                          </div>

                                          <h4 className="font-medium break-all pr-6">
                                            {trackingData?.ItemDescription}
                                          </h4>
                                        </div>
                                        <div className="ml-6 mt-2 sm:flex text-gray-500">
                                          <div>{trackingData?.Item}</div>
                                          {trackingData?.EquipmentNumber ? (
                                            <div className="sm:ml-4">
                                              EQ#{' '}
                                              {trackingData?.EquipmentNumber}{' '}
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          {trackingData?.SerialNumber ? (
                                            <div className="sm:ml-4">
                                              SER# {trackingData?.SerialNumber}{' '}
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          {trackingData?.LocationRemarks ? (
                                            <div className="sm:ml-4">
                                              {trackingData?.LocationRemarks}{' '}
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                      <div className="xl:w-1/12 md:w-1/5  w-1/3 md:text-center">
                                        {isCheckDollerProcingNonZero(
                                          Number(trackingData?.Price)?.toFixed(
                                            2,
                                          ),
                                        ) ? (
                                          <>
                                            <div className="md:hidden block text-gray-500">
                                              Price
                                            </div>
                                            <span>
                                              {renderUSCurrency(trackingData?.Price)}
                                            </span>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                                        <div className="md:hidden block text-gray-500">
                                          Quantity
                                        </div>
                                        {trackingData?.Quantity}
                                      </div>
                                      <div className="xl:w-1/12 md:w-1/5 w-1/3 text-right">
                                        {isCheckDollerProcingNonZero(
                                          Number(
                                            trackingData?.totalPriceOfOneProduct,
                                          )?.toFixed(2),
                                        ) ? (
                                          <>
                                            <div className="md:hidden block text-gray-500">
                                              Total
                                            </div>
                                            <span className="font-medium">
                                              {renderUSCurrency(trackingData?.totalPriceOfOneProduct)}
                                            </span>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
          </div>

          {orderDetailsData.shipmentDetailObj.length &&
          isCheckDollerProcingNonZero(
            orderDetailsData?.orderDetailObj?.OrderCost?.toFixed(2),
          ) ? (
            <>
              <div className="totalBar border-t-0 border border-gray-200">
                <div className="flex text-right tex-total border-b border-gray-200 pt-4 pb-4 mb-4 px-4">
                  <div className="xl:w-11/12 md:w-10/12 w-3/5">
                    <span className="font-medium">Sub Total: </span>{' '}
                  </div>
                  <div className="xl:w-1/12 md:w-2/12 w-2/5">
                    <span className="pl-8 font-medium">
                      {renderUSCurrency(totalCost)}
                    </span>
                  </div>
                </div>

                <>
                  {orderDetailsData?.orderDetailObj?.Tax ? (
                    <div className="flex text-right tex-total border-b border-gray-200 pb-4 mb-4 px-4">
                      <div className="xl:w-11/12 md:w-10/12 w-3/5">
                        <span className="font-medium">Tax: </span>{' '}
                      </div>
                      <div className="xl:w-1/12 md:w-2/12 w-2/5">
                        <span className="pl-8 font-medium">
                          {renderUSCurrency(
                            orderDetailsData?.orderDetailObj?.Tax,
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {orderDetailsData?.orderDetailObj?.Freight ? (
                    <div className="flex text-right sub-total border-b border-gray-200 pb-4 mb-4 px-4">
                      <div className="xl:w-11/12 md:w-10/12 w-3/5">
                        <span className="font-medium">
                          Shipping & Handling:
                        </span>
                      </div>
                      <div className="xl:w-1/12 md:w-2/12 w-2/5">
                        <span className="pl-8 font-medium">
                          {renderUSCurrency(
                            orderDetailsData?.orderDetailObj?.Freight,
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {orderDetailsData?.orderDetailObj?.Discount ? (
                    <div className="flex text-right order-total border-b border-gray-200 pb-4 mb-4 px-4">
                      <div className="xl:w-11/12 md:w-10/12 w-3/5">
                        <span className="font-bold">Discount:</span>
                      </div>
                      <div className="xl:w-1/12 md:w-2/12 w-2/5">
                        <span className="pl-8 font-bold">
                          {renderUSCurrency(
                            orderDetailsData?.orderDetailObj?.Discount,
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {isCheckDollerProcingNonZero(
                    orderDetailsData.orderDetailObj.OrderCost.toFixed(2),
                  ) ? (
                    <div className="flex text-right order-total border-b border-gray-200 pb-4 px-4">
                      <div className="xl:w-11/12 md:w-10/12 w-3/5">
                        <span className="font-bold">Order Total:</span>
                      </div>
                      <div className="xl:w-1/12 md:w-2/12 w-2/5">
                        <span className="pl-8 font-bold">
                          {renderUSCurrency(
                            orderDetailsData.orderDetailObj.OrderCost,
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </div>
            </>
          ) : (
            ''
          )}

          {/* Cancel items */}
          {orderDetailsData.orderDetailObj.CanceledItems.length >= 1 && (
            <div className="mt-10">
              <h3 className="text-md font-medium">Canceled Items</h3>
              <div className="productListing mt-4 border border-gray-200 rounded-md">
                <div className="md:flex hidden bg-gray-100 border-b border-gray-200 py-3 px-4">
                  <div className="xl:w-9/12 md:w-2/5 w-full">Product</div>
                  <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                    {orderDetailsData?.orderDetailObj?.CanceledItems?.length &&
                    IsPriceHeaderShow(
                      orderDetailsData?.orderDetailObj?.CanceledItems,
                    ) ? (
                      <>Price</>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                    Quantity
                  </div>
                  <div className="xl:w-1/12 md:w-1/5  w-1/3 text-right">
                    {orderDetailsData?.orderDetailObj?.CanceledItems?.length &&
                    IsTotalHeaderShow(
                      orderDetailsData?.orderDetailObj?.CanceledItems,
                    ) ? (
                      <>Total</>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="[&>*:last-child]:border-0">
                  {orderDetailsData?.orderDetailObj &&
                    orderDetailsData?.orderDetailObj?.CanceledItems.map(
                      (cancelItem: any, CanceledItemsIndex: number) => {
                        return (
                          <div
                            className="flex border-b border-gray-200 py-4 px-4 flex-wrap"
                            key={'CanceledItems' + CanceledItemsIndex}
                          >
                            <div className="xl:w-9/12 md:w-2/5 w-full md:mb-0 mb-4">
                              <div className="md:hidden block text-gray-500">
                                Product
                              </div>
                              <div className="flex items-center">
                                {cancelItem?.tonerClass ? (
                                  <div
                                    className="w-4 h-4 rounded-full mr-2 ItemDescription"
                                    style={{
                                      backgroundColor: cancelItem?.tonerClass,
                                    }}
                                  ></div>
                                ) : (
                                  ''
                                )}
                                <h4 className="font-medium break-all pr-6">
                                  {cancelItem?.ItemDescription}
                                </h4>
                              </div>
                              <div className="mt-2 sm:flex text-sm text-gray-600">
                                <div>{cancelItem?.Item}</div>
                                {cancelItem?.EquipmentNumber ? (
                                  <div className="sm:ml-4">
                                    EQ# {cancelItem?.EquipmentNumber}{' '}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {cancelItem?.SerialNumber ? (
                                  <div className="sm:ml-4">
                                    SER# {cancelItem?.SerialNumber}{' '}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {cancelItem?.LocationRemarks ? (
                                  <div className="sm:ml-4">
                                    {cancelItem?.LocationRemarks}{' '}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="xl:w-1/12 md:w-1/5  w-1/3 md:text-center">
                              {isCheckDollerProcingNonZero(
                                Number(cancelItem?.Price)?.toFixed(2),
                              ) ? (
                                <>
                                  <div className="md:hidden block text-gray-500">
                                    Price
                                  </div>
                                  <span>
                                    {renderUSCurrency(cancelItem?.Price)}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                              <div className="md:hidden block text-gray-500">
                                Quantity
                              </div>
                              {cancelItem?.Quantity}
                            </div>
                            <div className="xl:w-1/12 md:w-1/5 w-1/3 text-right">
                              {isCheckDollerProcingNonZero(
                                Number(
                                  cancelItem?.totalPriceOfOneProduct,
                                )?.toFixed(2),
                              ) ? (
                                <>
                                  <div className="md:hidden block text-gray-500">
                                    Total
                                  </div>
                                  <span className="font-medium">
                                    {renderUSCurrency(
                                      cancelItem?.totalPriceOfOneProduct,
                                    )}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        );
                      },
                    )}
                </div>
                {isCheckDollerProcingNonZero(cancelTotalCost.toFixed(2)) ? (
                  <div className="flex text-right tex-total border-t border-gray-200 py-4 px-4">
                    <div className="xl:w-11/12 md:w-10/12 w-3/5">
                      <span className="font-medium">Canceled Total:</span>
                    </div>
                    <div className="xl:w-1/12 md:w-2/12 w-2/5">
                      <span className="pl-8 font-medium">
                        {renderUSCurrency(cancelTotalCost)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}

          {/*ReturnItems  */}
          {orderDetailsData?.orderDetailObj?.ReturnItems?.length >= 1 && (
            <div className="mt-10">
              <h3 className="text-md font-medium">Returned Items</h3>
              <div className="productListing mt-4 border border-gray-200 rounded-md">
                <div className="md:flex hidden bg-gray-100 border-b border-gray-200 py-3 px-4">
                  <div className="xl:w-9/12 md:w-2/5 w-full">Product</div>
                  <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                    {orderDetailsData?.orderDetailObj?.ReturnItems?.length &&
                    IsPriceHeaderShow(
                      orderDetailsData?.orderDetailObj?.ReturnItems,
                    ) ? (
                      <>Price</>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                    Quantity
                  </div>
                  <div className="xl:w-1/12 md:w-1/5  w-1/3 text-right">
                    {orderDetailsData?.orderDetailObj?.ReturnItems?.length &&
                    IsTotalHeaderShow(
                      orderDetailsData?.orderDetailObj?.ReturnItems,
                    ) ? (
                      <>Total</>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="[&>*:last-child]:border-0">
                  {orderDetailsData?.orderDetailObj &&
                    orderDetailsData?.orderDetailObj?.ReturnItems.map(
                      (returnItem: any, ReturnItemsIndex: any) => {
                        return (
                          <div
                            className="flex border-b border-gray-200 py-4 px-4 flex-wrap"
                            key={'ReturnItemsIndex' + ReturnItemsIndex}
                          >
                            <div className="xl:w-9/12 md:w-2/5 w-full  md:mb-0 mb-4">
                              <div className="md:hidden block text-gray-500">
                                Product
                              </div>
                              <div className="flex items-center">
                                {returnItem?.tonerClass ? (
                                  <div
                                    className="w-4 h-4 rounded-full mr-2 ItemDescription"
                                    style={{
                                      backgroundColor: returnItem?.tonerClass,
                                    }}
                                  ></div>
                                ) : (
                                  ''
                                )}
                                <h4 className="font-medium break-all pr-6">
                                  {returnItem?.ItemDescription}
                                </h4>
                              </div>
                              <div className="mt-2 sm:flex text-sm text-gray-600">
                                <div>{returnItem?.Item}</div>
                                {returnItem?.EquipmentNumber ? (
                                  <div className="sm:ml-4">
                                    EQ# {returnItem?.EquipmentNumber}{' '}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {returnItem?.SerialNumber ? (
                                  <div className="sm:ml-4">
                                    SER# {returnItem?.SerialNumber}{' '}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {returnItem?.LocationRemarks ? (
                                  <div className="sm:ml-4">
                                    {returnItem?.LocationRemarks}{' '}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="xl:w-1/12 md:w-1/5  w-1/3 md:text-center">
                              {isCheckDollerProcingNonZero(
                                Number(returnItem?.Price)?.toFixed(2),
                              ) ? (
                                <>
                                  <div className="md:hidden block text-gray-500">
                                    Price
                                  </div>
                                  <span>
                                    {renderUSCurrency(returnItem?.Price)}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="xl:w-1/12 md:w-1/5 w-1/3 text-center">
                              <div className="md:hidden block text-gray-500">
                                Quantity
                              </div>
                              {returnItem?.Quantity}
                            </div>
                            <div className="xl:w-1/12 md:w-1/5 w-1/3 text-right">
                              {isCheckDollerProcingNonZero(
                                Number(
                                  returnItem?.totalPriceOfOneProduct,
                                )?.toFixed(2),
                              ) ? (
                                <>
                                  <div className="md:hidden block text-gray-500">
                                    Total
                                  </div>
                                  <span className="font-medium">
                                    {renderUSCurrency(
                                      returnItem?.totalPriceOfOneProduct,
                                    )}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        );
                      },
                    )}
                </div>
                {isCheckDollerProcingNonZero(returnTotalCost.toFixed(2)) ? (
                  <div className="flex text-right tex-total border-t border-gray-200 py-4 px-4">
                    <div className="xl:w-11/12 md:w-10/12 w-3/5">
                      <span className="font-medium">Return Order Total:</span>
                    </div>
                    <div className="xl:w-1/12 md:w-2/12 w-2/5">
                      <span className="pl-8 font-medium">
                        {renderUSCurrency(returnTotalCost)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}

          <div className="flex justify-end mt-6 buttonBar">
            <button
              className={`borderedBtn linkText hover:text-black mr-2`}
              onClick={() => {
                window.print();
              }}
            >
              <div>Print</div>
            </button>

            {!IsOnlyReturnItem && hasPermission(CustomerTokens.PlaceOrder) ? (
              <button
                className={`primaryBtn bgColor`}
                onClick={() => {
                  onHandlerOrderAgain(
                    orderDetailsData.orderDetailObj.OrderItems,
                    orderDetailsData.orderDetailObj.CanceledItems,
                  );
                }}
              >
                Reorder
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        <ComponentLoading isShowLoader={true} />
      )}
    </>
  );
};
