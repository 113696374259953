import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Api } from '../../../../shared/api/api';
import storage from '../../../../utils/storage/storage';
import { getLocalTimezone, getTime } from '../../../../utils/utils';
import TaskDetails from './task-details-table/task-details';
import { PushpinIcon } from '../../../../assets/svg/pushpin-icon';
import { EquipementInfoIcon } from '../../../../assets/svg/equip-info-icon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { ClientStorageKey, TENANT } from '../../../../constant/constant';
import { CallIcon } from '../../../../assets/svg/call-icon';
import { DangerIcon } from '../../../../assets/svg/danger-icon';
import { SquareUserIcon } from '../../../../assets/svg/square-userl-icon';
import {
  Form,
  FormType,
  SubmitBtn,
  FormItems,
} from '../../../../components/form';
import { useModal } from '../../../../context/ModalConext';
import { Loader } from '../../../../components/ui';
import { Chip, Status } from '../../../../components/ui/Chip';
import { ServiceRequestNote } from './service-request-notes';
import { ComponentLoading } from '../../../../shared/component-loader/component-loading';
import { useUser } from '../../../../context/UserContext';
import { CustomerTokens } from '../../../../types/Customer';

const ServiceRequestDetail = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query: any = new URLSearchParams(search);
  const { openModal, closeModal } = useModal();
  const { serviceRequestId } = useParams();
  const [isReopenBtnDisable, setReopenBtnDisable] = useState(false);

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    const obj = {
      timeRange: parseInt(query.get('timeRange')),
      location: query.get('location'),
      viewMyCalls:
        query.get('viewMyCalls') &&
        query.get('viewMyCalls').toLowerCase() === 'true',
    };
    storage.setJSONItem(ClientStorageKey.RequestFilters, obj);
    history.back();
    // window.location.href = '/service/request-history';
  };
  const [requestDetailsData, setRequestDetailsData] = useState<any>(null);
  const [defaultSetValue, setDefaultValue] = useState<any>(null);
  const status = requestDetailsData?.ServiceRequestInfoDisplay?.Status;

  //When a service request has been finished, these statuses are considered as complete
  const isComplete = [ Status.Invoiced, Status.Completed, Status.Canceled, Status.OKToInvoice ].includes(status);

  //We split up the caller because when creating a service request, we for some reason, concatenate the name, number, and email with '--' :shrug: ?
  const [callerName, callerNumber, callerEmail ] =
    defaultSetValue?.Caller.split('--') ||
    requestDetailsData?.ServiceRequestInfoDisplay?.Caller.split('--',) || [];

  //This is used in the task details section, it's a bit of a mess, but it's used to display the problem description and notes
  const { ProblemDescription: problem, Notes } =
    defaultSetValue || requestDetailsData?.ServiceRequestInfoDisplay || { ProblemDescription: '', Notes: '' };


  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
    );

  const displayNoteBtn = tenantName !== TENANT.MARIMON && !isComplete;

  const { customerInfo: customer, hasPermission } = useUser();
  const locationId =
    customer.RestrictUserByLocationID &&
    customer.RestrictUserByLocationID.length
      ? customer.RestrictUserByLocationID.toString()
      : null;

  const requestDetails = async () => {
    try {
      const response = await Api.callAPI({
        url: `/api/v1/services/requestCall/${serviceRequestId}/${customer.CustomerId}`,
        body: { LocationID: locationId },
        method: 'PUT',
        options: {
          showErrorMessage: false,
        },
      });
      if (response?.data || response?.data?.ServiceRequestInfoDisplay || response?.status === 200) {
        let serviceRequestObj = response?.data?.ServiceRequestInfoDisplay;

        serviceRequestObj.Date = getTime(serviceRequestObj.Date);
        serviceRequestObj.CloseDate =
          serviceRequestObj.CloseDate !== null
            ? getTime(serviceRequestObj.CloseDate)
            : 'NotYetSet';
        serviceRequestObj.DispatchDate =
          serviceRequestObj.DispatchDate !== null
            ? getTime(serviceRequestObj.DispatchDate)
            : 'NotYetSet';

        const sortedData = response?.data?.ServiceRequests[0]?.length && response?.data?.ServiceRequests[0].sort((a: any, b: any) => a - b)
        let newRepairRemarks: any;
        if (sortedData && sortedData.length > 0) {
          for (const note of sortedData) {
            if (note && note.Notes) {
              newRepairRemarks = note;
              break;
            }
          }
        } else {
          newRepairRemarks = response?.data?.ServiceRequests[0];
        }
        const modifiedResponse = {
          ...response?.data,
          ServiceRequestInfoDisplay: serviceRequestObj,
          newRepairRemarks: newRepairRemarks
        };

        setRequestDetailsData(modifiedResponse);

        const defaultValueSet =
          response?.data?.ServiceRequests?.length >= 0 &&
          response?.data?.ServiceRequests[0];
        setDefaultValue(defaultValueSet);
      }
    } catch (e) {
      navigate('/service/request-history');
    }
  };

  const RepairRemarks = (value: any) => {
    if (!value) return [];
    const splitValues = value.split('\n');
    const listElements = splitValues.map((item: any, index: number) => {
      if (item.trim()) {
        return (
          <ServiceRequestNote
          key={item}
          note={item}
          isLastnote={index === splitValues.length - 1}
          />
        );
      }
    });
    return listElements;
  };

  const LatestTask = (LatestTaskData: any) => {
    const splitValues = LatestTaskData.split('\n').filter((el: any) => el.trim() !== '' && el !== '\r');
    const sortedTaskDetailsForNotes =
      splitValues &&
      splitValues?.length > 0 &&
      splitValues?.sort((a: any, b: any) => a - b);

    if (sortedTaskDetailsForNotes && sortedTaskDetailsForNotes?.length > 0) {
      const listElements = sortedTaskDetailsForNotes.map(
        (item: any, index: number) => (
          <ServiceRequestNote key={item} note={item} isLastnote={index === sortedTaskDetailsForNotes.length - 1} />
        ),
      );
      return listElements;
    } else {
      return LatestTaskData;
    }
  };

  const onSubmitAddHandler = async (value: any) => {
    const noteObj = {
      SCCallID: requestDetailsData?.ServiceRequestInfoDisplay?.CallID,
      SCWorkOrderNumber:
        requestDetailsData?.ServiceRequestInfoDisplay?.WorkOrderNumber,
      Note: value?.note?.trim(),
      SCCallNumber: requestDetailsData?.ServiceRequestInfoDisplay?.CallNumber,
      System: 'FCP_CUSTOMER',
      EmailAddress: customer?.EmailAddress,
      successMessage: 'Your note has been submitted to our team for review!',
      Type: 'ADD_NOTE',
      Timezone : getLocalTimezone(),
      Date: new Date()
    };
    await Api.callAPI({
      url: `/api/v1/zendesk/ooa-portal/technician-update`,
      method: 'post',
      body: noteObj,
      options: {
        successMessage: noteObj?.successMessage,
        errorMessage: 'Error while adding note.',
      },
    });

    closeModal();
    requestDetails().then();
  };

  const onSubmitCancelHandler = async (value: any) => {
    const noteObj = {
      SCCallID: requestDetailsData?.ServiceRequestInfoDisplay?.CallID,
      SCWorkOrderNumber:
        requestDetailsData?.ServiceRequestInfoDisplay?.WorkOrderNumber,
      Note: value?.note?.trim(),
      SCCallNumber: requestDetailsData?.ServiceRequestInfoDisplay?.CallNumber,
      System: 'FCP_CUSTOMER',
      EmailAddress: customer.EmailAddress,
      successMessage: 'Your note has been submitted to our team for review!',
      Type: 'CANCEL_NOTE'
    };

    await Api.callAPI({
      url: `/api/v1/zendesk/ooa-portal/technician-update`,
      method: 'post',
      body: noteObj,
      options: {
        successMessage: noteObj.successMessage,
        errorMessage: 'Error while adding note.',
      },
    });
    closeModal();
    requestDetails().then();
  };

  const onSubmitReOpenHandler = async (queryForValidNewRequest: any) => {
    try {
      setReopenBtnDisable(true);
      const response = await Api.callAPI({
        url: `/api/v1/services/checkNewRequest/${queryForValidNewRequest.EquipmentID}/${queryForValidNewRequest.CustomerId}`,
        method: 'get',
        queryParams: queryForValidNewRequest,
        options: {
          successMessage: false,
          errorMessage: false,
        },
      });
      if (response.data && response.data.ServiceRequestExist) {
        if (confirm(`There is already an open Service Request for this device: #${response.data.WorkOrderNumber}, would you like to view it now?`)) {
          navigate(
            `/service/service-request-detail/${response.data.WorkOrderID}`,
          );
          window.location.reload();
        }
      } else {
        navigate(
          `/service/request/${queryForValidNewRequest.EquipmentID}/${serviceRequestId}`,
        );
      }
    } catch (e) {
    } finally {
      setReopenBtnDisable(false);
    }
  };

  const checkProblemDescriptionIsEmpty = (array: any) => {
    let flag = true;
    array.forEach((data: any) => {
      if (data.trim()) {
        flag = false;
      }
    });
    return flag;
  };

  useEffect(() => {
    requestDetails().then();
    window.history.pushState({}, '');
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const [
    enableCommunicationFromCustomersToZendesk,
    setEnableCommunicationFromCustomersToZendesk,
  ] = useState(false);

  useEffect(() => {
    setEnableCommunicationFromCustomersToZendesk(
      customer.GlobalSettings.EnableCommunicationFromCustomersToZendesk ===
        undefined ||
        customer.GlobalSettings.EnableCommunicationFromCustomersToZendesk ===
          null
        ? true
        : customer.GlobalSettings.EnableCommunicationFromCustomersToZendesk,
    );
  }, [customer]);

  console.log({ requestDetailsData})

  return (
    <div>
      {requestDetailsData ? (
        <div>


          <div className="mb-4 sm:flex justify-between">
            <div className='leftSideBox'>
              <div className='flex lg:items-center mb-5'>
                <h1 className="lg:text-2xl text-xl font-medium">
                  Request Details - {requestDetailsData?.ServiceRequestInfoDisplay?.WorkOrderNumber}
                </h1>
                <div className="ml-4">
                  <div className={'box'}>
                    <div className={'box-inner'}>
                      <Chip status={requestDetailsData?.ServiceRequestInfoDisplay?.Status} />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-sm">

                {requestDetailsData?.ServiceRequestInfoDisplay?.TicketNumbers && (
                    <div className='mb-1'>
                      <span className="text-gray-600">Ticket Number: </span>
                      <span className="text-gray-800 ml-1">
                        {requestDetailsData.ServiceRequestInfoDisplay.TicketNumbers}
                      </span>
                    </div>
                  )}

                  {requestDetailsData?.ServiceRequestInfoDisplay?.CallType && (
                    <div className='mb-1'>
                      <span className="text-gray-600">Request Type:</span>
                      <span className="text-gray-800 ml-1">
                        {requestDetailsData?.ServiceRequestInfoDisplay?.CallType}
                      </span>
                    </div>
                  )}

                  {requestDetailsData?.ServiceRequestInfoDisplay?.DispatchDate &&
                  requestDetailsData?.ServiceRequestInfoDisplay?.DispatchDate !==
                    'NotYetSet' ? (
                    <div className='mb-1'>
                      <span className="text-gray-600">Assigned:</span>
                      <span className="text-gray-800 ml-1">
                        {
                          requestDetailsData?.ServiceRequestInfoDisplay
                            ?.DispatchDate
                        }
                      </span>
                    </div>
                  ) : (
                    ''
                  )}

                  {requestDetailsData?.ServiceRequestInfoDisplay?.CloseDate &&
                  requestDetailsData?.ServiceRequestInfoDisplay?.CloseDate !==
                    'NotYetSet' ? (
                    <div className='mb-1'>
                      <span className="text-gray-600">Completed   :</span>
                      <span className="text-gray-800 ml-1">
                        {requestDetailsData?.ServiceRequestInfoDisplay?.CloseDate}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}

                  {requestDetailsData?.ServiceRequestInfoDisplay?.EnteredBy && (
                    <div className='mb-1'>
                      <span className="text-gray-600">Entered on Web By:</span>
                      <span className="text-gray-800 ml-1">
                        {requestDetailsData?.ServiceRequestInfoDisplay?.EnteredBy}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="rightSideBox sm:flex flex-col items-end sm:pt-0 pt-4 sm:pb-0 pb-4">
              <div className="text-sm sm:text-right font-medium sm:mt-0 mt-2 ">
                <div className="text-gray-600">Placed On:</div>
                <div className="text-gray-800 text-xl">
                  {requestDetailsData?.ServiceRequestInfoDisplay?.Date}
                </div>
              </div>

              {displayNoteBtn ? (
                <div className="flex items-center mt-4">
                  {enableCommunicationFromCustomersToZendesk ? (
                    <button
                      className={`borderedBtn mr-2 linkText hover:text-black`}
                      onClick={() => {
                        openModal({
                          content: (
                            <div>
                              <Form onSubmit={onSubmitAddHandler}>
                                <FormItems
                                  className="p-6"
                                  items={[
                                    {
                                      name: 'note',
                                      label: '',
                                      rows: 5,
                                      type: FormType.TEXTAREA,
                                      placeholder: 'Write your note here..',
                                      required: true,
                                      errors: {
                                        required: 'Please enter note.',
                                      },
                                      maxLength: 1000
                                    },
                                  ]}
                                />
                                <div className="flex justify-end py-4 px-6 border-t border-gray-200 ">
                                  <button
                                    onClick={() => closeModal()}
                                    className={`borderedBtn mr-2 linkText hover:text-black`}
                                  >
                                    Cancel
                                  </button>
                                  <SubmitBtn className="commonBtn">
                                    Submit
                                  </SubmitBtn>
                                </div>
                              </Form>
                            </div>
                          ),
                          options: {
                            title: 'Add Note',
                            // footer: <p>footer</p>
                          },
                        });
                      }}
                    >
                      Add note
                    </button>
                  ) : (
                    ''
                  )}
                  {enableCommunicationFromCustomersToZendesk ? (
                    <button
                      className={`primaryBtn bgColor`}
                      onClick={() => {
                        openModal({
                          content: (
                            <div>
                              <Form onSubmit={onSubmitCancelHandler}>
                                <FormItems
                                  className="p-6"
                                  items={[
                                    {
                                      name: 'note',
                                      label: '',
                                      rows: 5,
                                      type: FormType.TEXTAREA,
                                      placeholder: 'Write your note here..',
                                      required: true,
                                      errors: {
                                        required: 'Please enter note.',
                                      },
                                      maxLength: 1000,
                                    },
                                  ]}
                                />
                                <div className="flex justify-end py-4 px-6 border-t border-gray-200">
                                  <button
                                    onClick={() => closeModal()}
                                    className={`borderedBtn mr-2 linkText hover:text-black`}
                                  >
                                    Cancel
                                  </button>
                                  <SubmitBtn className={`commonBtn`}>
                                    Submit
                                  </SubmitBtn>
                                </div>
                              </Form>
                            </div>
                          ),
                          options: {
                            title: 'Cancel Request',
                            // footer: <p>footer</p>
                          },
                        });
                      }}
                    >
                      Cancel Request
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
              {isComplete && hasPermission(CustomerTokens.PlaceServiceRequest) ? (
                <div>
                  <button
                    className={`mt-4  ${
                      isReopenBtnDisable ? 'disabled' : ''
                    }`}
                  >
                    <div
                      className={'flex items-center relative primaryBtn bgColor'}
                      onClick={async () => {
                        await onSubmitReOpenHandler({
                          CustomerId: customer.CustomerId,
                          EquipmentID:
                            requestDetailsData?.ServiceRequestInfoDisplay
                              ?.EquipmentID,
                        });
                      }}
                    >
                      Reopen Request
                      {isReopenBtnDisable ? (
                        <span className={`text-white absolute left-0 top-0 w-full h-full bg-black opacity-80 pt-1.5 ` }>
                          <Loader />
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          {/* Section 1 */}



          {/* Section - 2 dynamic */}
          <div>
            {requestDetailsData?.ServiceRequestInfoDisplay?.RepairRemarks &&
              requestDetailsData?.ServiceRequestInfoDisplay?.RepairRemarks !==
                '' && (
                <div className="mb-4">
                  <h6 className="text-sm text-gray-600 mb-2">
                    Service Request Notes
                  </h6>
                  <div className="border border-gray-300 py-2 px-3 rounded-md text-sm overflow-y-auto h-full" style={{maxHeight: '12rem'}}>
                    <div>
                      {RepairRemarks(
                        requestDetailsData?.ServiceRequestInfoDisplay
                          ?.RepairRemarks,
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
          {requestDetailsData?.newRepairRemarks?.Notes ? (
              <div className="mb-4">
                <h6 className="text-sm text-gray-600 mb-2">
                  Latest Task Notes
                </h6>
                <div className="border border-gray-300 py-2 px-3 rounded-md text-sm overflow-auto"  style={{maxHeight: '12rem'}}>
                  {LatestTask(
                    requestDetailsData?.newRepairRemarks?.Notes,
                  )}
                </div>
              </div>
            ) : ('')}

          {/* Section 3 */}
          <div className="sm:flex gap-8 md:mb-10 mb-4">
            <div className="sm:mb-0 mb-4 sm:w-96">
              <div className="flex items-center mb-4">
                <div>
                  {' '}
                  <EquipementInfoIcon />{' '}
                </div>
                <h4 className="ml-2 md:text-lg text-base font-semibold">
                  Equipment Information
                </h4>
              </div>
              <div className="ml-7 text-sm">
                <div className='mb-1'>
                  <span className="text-gray-600">Equipment Information:</span>
                  {!requestDetailsData?.ServiceRequestInfoDisplay
                    ?.IsHistorical ? (
                    <span className="text-gray-600 ml-1">
                      <Link
                        to={`/customer/device-detail/${requestDetailsData?.ServiceRequestInfoDisplay?.EquipmentID}`}
                        className="linkText"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {
                          requestDetailsData?.ServiceRequestInfoDisplay
                            ?.EquipmentNumber
                        }
                      </Link>
                    </span>
                  ) : (
                    <span className="text-gray-800 ml-1">
                      {
                        requestDetailsData?.ServiceRequestInfoDisplay
                          ?.EquipmentNumber
                      }
                    </span>
                  )}
                </div>
                <div className='mb-1'>
                  <span className="text-gray-600">Serial Number:</span>
                  <span className="text-gray-800 ml-1">
                    {
                      requestDetailsData?.ServiceRequestInfoDisplay
                        ?.SerialNumber
                    }
                  </span>
                </div>
                <div className='mb-1'>
                  <span className="text-gray-600">Model:</span>
                  <span className="text-gray-800 ml-1">
                    {requestDetailsData?.ServiceRequestInfoDisplay?.Model}
                  </span>
                </div>
                <div className='mb-1'>
                  <span className="text-gray-600">Description:</span>
                  <span className="text-gray-800 ml-1">
                    {requestDetailsData?.ServiceRequestInfoDisplay?.Description}
                  </span>
                </div>
                <div className='mb-1'>
                  <span className="text-gray-600">Location Remark:</span>
                  <span className="text-gray-800 ml-1">
                    {
                      requestDetailsData?.ServiceRequestInfoDisplay
                        ?.LocationRemark
                    }
                  </span>
                </div>
              </div>
            </div>
            <div className="sm:w-96">
              <div className="flex items-center mb-4">
                <div>
                  {' '}
                  <PushpinIcon />{' '}
                </div>
                <h4 className="ml-2 lg:text-lg text-base font-semibold">
                  Equipment Location
                </h4>
              </div>
              <div className="ml-7 text-sm">
                <p>
                  {requestDetailsData?.ServiceRequestInfoDisplay?.CustomerName}
                  <br />
                  {requestDetailsData?.ServiceRequestInfoDisplay?.Address}
                  <br />
                  {requestDetailsData?.ServiceRequestInfoDisplay?.City},{' '}
                  {requestDetailsData?.ServiceRequestInfoDisplay?.State},{' '}
                  {requestDetailsData?.ServiceRequestInfoDisplay?.Zip}
                  <br />
                  {requestDetailsData?.ServiceRequestInfoDisplay?.Country}
                </p>
              </div>
            </div>
          </div>

          {requestDetailsData?.ServiceRequests?.length > 1 ? (
            <>
              <div className="mb-10">
                <div className="h-250">
                  <TaskDetails
                    requestDetailsData={requestDetailsData?.ServiceRequests}
                    setDefaultValue={setDefaultValue}
                  />
                </div>
              </div>

              {defaultSetValue && (
                <>
                  <div key={defaultSetValue?.CallNumber} className="mb-6 fade-in">
                    <h4 className="text-xl font-semibold">Task Details</h4>
                    <div className="sm:flex items-center justify-between">
                      <div className='text-sm'>
                        <div>
                          <span className="text-gray-600 flex">
                          Task Number: {defaultSetValue?.CallNumber}
                          <span className="ml-2">{defaultSetValue?.Status && <Chip status={defaultSetValue?.Status} />}</span>
                          </span>
                        </div>
                        <div>
                          <span className="text-gray-600">Completed </span>
                          {defaultSetValue?.CloseDate &&
                            defaultSetValue.CloseDate !== 'NotYetSet' && (
                              <span className="text-gray-800 ml-1">
                                {defaultSetValue?.CloseDate ? getTime(defaultSetValue?.CloseDate) : '' }
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="sm:flex sm:mt-0 mt-4 flex-col items-end">
                        <div className="text-sm text-gray-600 mb-1">
                          Created
                        </div>
                        <div className="text-xl font-semibold">
                          {' '}
                          {getTime(defaultSetValue?.CloseDate || defaultSetValue?.Date)}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            ''
          )}
          {/* Task*/}

          {/* 3 Infoboxes */}

          <div key={defaultSetValue?.Date} className="sm:flex lg:flex-nowrap flex-wrap lg:gap-8 gap-0 fade-in">
            {(!!callerName || !!callerEmail || !!callerNumber) && (
              <div className="lg:w-1/3 sm:w-1/2 sm:mb-0 mb-4 md:pr-4">
                <div className="flex items-center mb-4">
                  <div>
                    <CallIcon />
                  </div>
                  <h4 className="ml-2 text-base font-semibold">
                    Contact Information
                  </h4>
                </div>
                <div className="ml-7 text-sm">
                  { !!callerName && <div className='mb-1'>Name: {callerName.trim()}</div> }
                  { callerNumber && <div className='mb-1'>Phone: { callerNumber.trim()}</div> }
                  { callerEmail && <div className='mb-1'>Email: { callerEmail.trim() }</div> }
                </div>
              </div>
            )}

            {problem.length > 0 && !checkProblemDescriptionIsEmpty(problem) && (
                <div className="lg:w-1/3 sm:w-1/2 lg:px-0 am:px-2 px-0 sm:mb-0 mb-4">
                  <div className="flex items-center mb-4">
                      <div>
                        <DangerIcon />
                      </div>
                      <h4 className="ml-2 text-base font-semibold">
                        Original Problem
                      </h4>
                  </div>
                  <div className="ml-7 text-sm">
                    <div className="xl:flex grow">
                      <div className="text-gray-600 grow min-w-fit">
                        Problem Description:
                      </div>
                      <div className="text-gray-800 xl:ml-1 grow w-full">
                        {problem?.map(
                          (value: any, index: any) => {
                            return <p key={'problemDescription' + index}>{value}</p>;
                          },
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {
              (
                requestDetailsData
                && requestDetailsData.ServiceRequestInfoDisplay
                && ((
                  requestDetailsData.ServiceRequestInfoDisplay.PrefFullName
                  && !requestDetailsData.ServiceRequestInfoDisplay.PrefFullName.includes('OOA')
                ) || requestDetailsData.ServiceRequestInfoDisplay.Notes)
              ) ? <div className="lg:w-1/3 sm:w-full lg:mt-0 sm:mt-6">
                <div className="flex items-center mb-4">
                  <div>
                    <SquareUserIcon />
                  </div>
                  <h4 className="ml-2 text-base font-semibold">
                    Technician Details
                  </h4>
                </div>
                <div className="ml-7 text-sm">
                  {requestDetailsData?.ServiceRequestInfoDisplay?.PrefFullName &&
                    !requestDetailsData?.ServiceRequestInfoDisplay?.PrefFullName.includes(
                      'OOA',
                    ) && (
                      <div className="flex mb-1">
                        <div className="text-gray-600">Name:</div>
                        <div className="text-gray-800 ml-1">
                          {
                            requestDetailsData?.ServiceRequestInfoDisplay
                              ?.PrefFullName
                          }
                        </div>
                      </div>
                    )}
                  {!!Notes &&
                    <div className="flex grow mb-1">
                      <div className="text-gray-600 grow min-w-fit">Note:</div>
                      <div className="text-gray-800 ml-1 grow w-full overflow-auto max-h-[20vh]">
                        {RepairRemarks(Notes)}
                      </div>
                    </div> }

                </div>
              </div> : ''
            }
          </div>
        </div>
      ) : <ComponentLoading isShowLoader={true}/>}
    </div>
  );
};

export default ServiceRequestDetail;
