import React, { useEffect, useRef, useState } from 'react';
import {
  CancelBtn,
  Form,
  FormItem,
  FormRef,
  FormType,
  InputMask,
  SubmitBtn,
} from '../../../../../components/form';
import { Api } from '../../../../../shared/api/api';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../../../store/notification/notification.action';
import { useUser } from '../../../../../context/UserContext';

export const AddDevice = () => {
  const { customerInfo } = useUser();
  const formRef: FormRef = useRef(null);
  const [locations, setLocations] = useState([]);
  const [devices, setDevices] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSelectedDevice, setHasSelectedDevice] = useState(false);
  const [replaceReason, setReplaceReason] = useState('');

  const dispatch = useDispatch();

  const locationLabelValueArr = locations.map((data) => {
    return {
      value: data.CustomerID,
      label: `${data.CustomerName} - ${data.Address} ${data.City} ${data.State} ${data.Zip}`,
    };
  });

  const deviceLabelValueArr = devices.map((data) => {
    return {
      value: data.EquipmentID,
      label: data.EquipmentNumber,
    };
  });

  const getEquipmentFromLocation = async (selectedValue) => {
    //Reset Devices
    formRef.clearInputs([
      'ReplaceDeviceData',
      'ReplaceReason',
      'WhatToDoWithOldDevice',
      'MovingLocation',
    ]);
    setDevices([]);

    let selected = selectedValue[0]?.value;
    if (!selected) return;
    try {
      const res = await Api.callAPI({
        url: `/api/v1/equipments/location/${selected}/${customerInfo.CustomerId}`,
        method: 'GET',
        options: {
          isLoading: false,
          showErrorMessage: false,
        },
      });
      setDevices(res.data);
    } catch (e) {}
  };

  const getLocations = async () => {
    try {
      const dataQuery = {
        CustomerID: customerInfo.CustomerId,
        EmailAddress: customerInfo.EmailAddress,
        UserId: customerInfo.UserId,
        HideOtherUserOrders: customerInfo.HideOtherUserOrders,
        LocationID: null,
      };

      const response = await Api.callAPI({
        url: `/api/v1/location`,
        method: 'put',
        body: dataQuery,
        options: {
          isLoading: false,
          showErrorMessage: false,
        },
      });
      if (response?.data && response?.data.length) {
        setLocations([...response?.data]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getLocations();
  }, []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    let {
      Location,
      Make,
      Model,
      SerialNumber,
      EquipmentNumber,
      IPAddress,
      MACAddress,
      ReplaceReason,
      PageCountBW,
      PageCountColor,
      CostCenter,
      FilePath,
      LocationRemark,
      Comments,
      WhatToDoWithOldDevice,
      RequestTonerBlack,
      RequestTonerCyan,
      RequestTonerMagenta,
      RequestTonerYellow,
      ReplaceDeviceData,
      MovingLocation,
    } = data;

    let EquipmentData = {
      CostCenter,
      EquipmentNumber,
      IPAddress,
      LocationRemark,
      MACAddress,
      Make,
      Model,
      PageCountBW,
      PageCountColor,
      SerialNumber,
      RequestTonerBlack,
      RequestTonerCyan,
      RequestTonerMagenta,
      RequestTonerYellow,
    };

    if (!!FilePath) {
      let formData = new FormData();
      formData.append('files', FilePath.files[0]);

      const fileUploadRes = await Api.callAPI({
        url: `/api/v1/equipments/configReports/upload`,
        method: 'post',
        body: formData,
        options: {
          isLoading: false,
          'Content-Type': 'multipart/form-data',
        },
      });

      FilePath = fileUploadRes.data;
    }

    let replaceDevice = !!ReplaceDeviceData
      ? devices.find((device) => device.EquipmentID === ReplaceDeviceData[0])
      : null;
    if (!replaceDevice)
      replaceDevice = {
        Address: '',
        City: '',
        State: '',
        Zip: '',
        Country: '',
        EquipmentNumber: '',
        EquipmentID: '',
        SerialNumber: '',
        CustomerName: '',
        CustomerNumber: '',
        WhatToDoWithOldDevice: '',
      };

    const postData = {
      EquipmentData,
      Location: locations.find((loc) => loc.CustomerID === Location[0]),
      Comments: Comments || '',
      FilePath,
      ReplaceDeviceData: {
        ...replaceDevice,
        Reason: ReplaceReason ? ReplaceReason[0] : '',
        WhatToDoWithOldDevice: WhatToDoWithOldDevice
          ? WhatToDoWithOldDevice
          : '',
      },
      UserInfo: customerInfo,
    };

    if (MovingLocation)
      postData.ReplaceDeviceData = {
        ...postData.ReplaceDeviceData,
        ...locations.find((loc) => loc.CustomerID === MovingLocation[0]),
      };

    const res = await Api.callAPI({
      url: `/api/v1/equipments`,
      method: 'post',
      body: postData,
      options: {
        isLoading: false,
      },
    });

    dispatch(
      showNotification({
        type: 'success',
        message: res?.data?.message || 'Your new device request has been submitted to our team for review.',
      }),
    );
    formRef.clear();
    setIsSubmitting(false);
    setHasSelectedDevice(false);
    window.scrollTo(0, 0);
  };

  const updateReplaceReason = (e) => {
    setReplaceReason(e[0]?.value);
    formRef.removeFormDef(['WhatToDoWithOldDevice', 'MovingLocation']);
    formRef.clearInputs(['WhatToDoWithOldDevice', 'MovingLocation']);
  }

  const updateReplaceDevice = (e) => {
    setHasSelectedDevice(!!e[0]?.value)
    formRef.removeFormDef(['WhatToDoWithOldDevice', 'MovingLocation']);
    formRef.clearInputs(['WhatToDoWithOldDevice', 'MovingLocation']);
  }

  return (
    <div>
      <h1 className="mb-4 text-2xl font-medium">Add New Device</h1>
      <Form formRef={formRef} onSubmit={onSubmit}>
        <FormItem
          key={locationLabelValueArr[0]?.value}
          formDef={{
            name: 'Location',
            label: 'Location',
            required: true,
            type: FormType.SELECT,
            options: locationLabelValueArr,
            selectShowCheckboxes: false,
            optionKeyMapObject: {
              labelKey: 'CustomerName',
              valueKey: 'CustomerID',
            },
            onChange: (e) => getEquipmentFromLocation(e),
            className: 'rmsc-slim',
          }}
        />

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-6">
          <FormItem
            formDef={{
              name: 'Make',
              label: 'Manufacturer',
              type: FormType.TEXT,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'Model',
              label: 'Model',
              type: FormType.TEXT,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'SerialNumber',
              label: 'Serial Number',
              type: FormType.TEXT,
              required: true,
            }}
          />

          <FormItem
            formDef={{
              name: 'EquipmentNumber',
              label: 'Equipment Number',
              type: FormType.TEXT,
            }}
          />

          <FormItem
            formDef={{
              name: 'IPAddress',
              label: 'IP Address',
              type: FormType.TEXT,
              mask: InputMask.IP,
            }}
          />

          <FormItem
            formDef={{
              name: 'MACAddress',
              label: 'MAC Address',
              type: FormType.TEXT,
              mask: InputMask.MAC,
            }}
          />

          <FormItem
            formDef={{
              name: 'PageCountBW',
              label: 'Page Count (B/W)',
              type: FormType.NUMBER,
              min: 0,
            }}
          />

          <FormItem
            formDef={{
              name: 'PageCountColor',
              label: 'Page Count (Color)',
              type: FormType.NUMBER,
              min: 0,
            }}
          />

          <FormItem
            formDef={{
              name: 'CostCenter',
              label: 'Cost Center/GL Code',
              type: FormType.TEXT,
            }}
          />
        </div>

        <FormItem
          formDef={{
            name: 'FilePath',
            label: 'Config Report',
            type: FormType.FILE,
            uploadAccept: '.doc, .docx, .pdf, .xls, .xlsx',
            uploadMaxSize: 25000000, //25MB 
            className: 'my-4',
          }}
        />

        <FormItem
          formDef={{
            name: 'LocationRemark',
            label: 'Location Remarks',
            type: FormType.TEXT,
            className: 'mb-4',
            maxLength: customerInfo?.LongerLocationRemarks ? 80 : 24,
          }}
        />

        <FormItem
          formDef={{
            name: 'Comments',
            label: 'Comments',
            type: FormType.TEXTAREA,
            maxLength: 200,
            className: 'mb-4'
          }}
        />

        <div className="mt-6">
          <div className="font-bold">Toner Request</div>
          <div className="text-xs italic mb-2">
            (For networked devices, toner shipments may be delayed until
            supplies are needed)
          </div>
          <div className="flex">
            <FormItem
              formDef={{
                name: 'RequestTonerBlack',
                label: 'Black',
                type: FormType.CHECKBOX,
                className: 'mr-2',
              }}
            />
            <FormItem
              formDef={{
                name: 'RequestTonerCyan',
                label: 'Cyan',
                type: FormType.CHECKBOX,
                className: 'mr-2',
              }}
            />
            <FormItem
              formDef={{
                name: 'RequestTonerMagenta',
                label: 'Magenta',
                type: FormType.CHECKBOX,
                className: 'mr-2',
              }}
            />
            <FormItem
              formDef={{
                name: 'RequestTonerYellow',
                label: 'Yellow',
                type: FormType.CHECKBOX,
              }}
            />
          </div>
        </div>
        {deviceLabelValueArr.length > 0 && (
          <div className="mt-6">
            <div className="font-bold">Device Replacement</div>
            <FormItem
              formDef={{
                name: 'ReplaceDeviceData',
                label: 'Device being replaced (if applicable)',
                type: FormType.SELECT,
                options: deviceLabelValueArr,
                selectShowCheckboxes: false,
                optionKeyMapObject: {
                  labelKey: 'EquipmentNumber',
                  valueKey: 'EquipmentID',
                },
                isMenuTop: true,
                className: 'rmsc-slim',
                selectIsClearable: true,
                onChange: (e) => updateReplaceDevice(e),
              }}
            />

            {hasSelectedDevice && (
              <div>
                <FormItem
                  formDef={{
                    name: 'ReplaceReason',
                    label: 'Reason for Replacing',
                    type: FormType.SELECT,
                    selectShowCheckboxes: false,
                    selectHideSearch: true,
                    isMenuTop: true,
                    required: true,
                    options: [
                      { label: 'Retiring', value: 'retiring' },
                      { label: 'Moving locations', value: 'moving' },
                      { label: 'Other', value: 'other' },
                    ],
                    className: 'rmsc-slim',
                    onChange: updateReplaceReason,
                  }}
                />

                {replaceReason == 'other' && (
                  <FormItem
                    key={locationLabelValueArr[0]?.value}
                    formDef={{
                      name: 'WhatToDoWithOldDevice',
                      label: 'What would you like to do with the old device?',
                      required: true,
                      type: FormType.TEXTAREA,
                    }}
                  />
                )}

                {replaceReason == 'moving' && (
                  <FormItem
                    key={locationLabelValueArr[0]?.value}
                    formDef={{
                      name: 'MovingLocation',
                      label: 'New Location',
                      type: FormType.SELECT,
                      options: locationLabelValueArr,
                      selectShowCheckboxes: false,
                      isMenuTop: true,
                      required: true,
                      optionKeyMapObject: {
                        labelKey: 'CustomerName',
                        valueKey: 'CustomerID',
                      },
                      className: 'rmsc-slim',
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
        <div className="h-[1px] bg-stone-200 w-full my-6"></div>
        <div className="flex justify-end">
          <CancelBtn
            className="rounded p-1 px-4 mr-4"
            ghost
            onClick={() => setDevices([])}
          >
            Cancel
          </CancelBtn>
          <SubmitBtn className="rounded p-1 px-4" loading={isSubmitting}>
            Add New Device
          </SubmitBtn>
        </div>
      </Form>
    </div>
  );
};
