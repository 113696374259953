import { useMemo } from "react";
import { FormItem, FormType } from "../../../components/form";
import { useFlexLayer } from "../../../hooks/useFlexlayer";
import { GeneralSetting } from "./generalSetting";

interface AuthZeroProps {
    isRestrictRegion: boolean,
    id: string,
    defaultValues: any,
}

export const AuthZeroSettings: React.FC<AuthZeroProps> = ({isRestrictRegion, id, defaultValues}) => {

    const { data: regionData } = useFlexLayer(isRestrictRegion ? `/customers/${id}/regions` : null);
    const { data: addressData } = useFlexLayer(`/customers/${id}/address`);

    const convertRegionData = regionData?.map(address => ({ label: address.region, value: address.region }));
    const convertAddressData = addressData?.map(address => ({ label: `${address.CustomerName} ${address.Address}`, value: address.CustomerID.toString() }));

    const defaultRestricted = useMemo(() => {
        return isRestrictRegion ? convertRegionData?.filter(role => defaultValues[`authZero RestrictUserByLocationID`]?.includes(role.value)) :
        convertAddressData?.filter(role => defaultValues[`authZero RestrictUserByLocationID`]?.includes(role.value));
    }, [defaultValues, convertRegionData, convertAddressData, isRestrictRegion]);

    const defaultPlaceOrderAddressID = useMemo(() => {
        return convertAddressData?.filter(role => defaultValues[`authZero PlaceOrderAddressID`]?.includes(+role.value));
    }, [defaultValues, convertAddressData]);

    const defaultServiceRequestAddressID = useMemo(() => {
        return convertAddressData?.filter(role => defaultValues[`authZero ServiceRequestAddressID`]?.includes(+role.value));
    }, [defaultValues, convertAddressData]);

    if(!regionData && !addressData) return null;

    return <>
            <FormItem
                formDef={{
                    name: `authZero RestrictUserByLocationID`,
                    label: `Default Restricted ${isRestrictRegion ? 'regions' : 'locations'}`,
                    type: FormType.SELECT,
                    isMenuTop: true,
                    selectMultiSelect: true,
                    selectShowSelectAllFilter: true,
                    selectShowSelectAll: true,
                    options: isRestrictRegion ? convertRegionData : convertAddressData,
                    selectAllValue: `All ${isRestrictRegion ? 'Regions' : 'Locations'}`,
                    defaultValue: defaultRestricted,
                }}
            />
            <FormItem
                formDef={{
                    name: `authZero PlaceOrderAddressID`,
                    label: `Default Order Confirmation Email Locations`,
                    type: FormType.SELECT,
                    isMenuTop: true,
                    selectMultiSelect: true,
                    selectShowSelectAllFilter: true,
                    selectShowSelectAll: true,
                    options: convertAddressData,
                    selectAllValue: `All Locations`,
                    defaultValue: defaultPlaceOrderAddressID,
                }}
            />
            <FormItem
                formDef={{
                    name: `authZero ServiceRequestAddressID`,
                    label: `Default Service Request Email Locations`,
                    type: FormType.SELECT,
                    isMenuTop: true,
                    selectMultiSelect: true,
                    selectShowSelectAllFilter: true,
                    selectShowSelectAll: true,
                    options: convertAddressData,
                    selectAllValue: `All Locations`,
                    defaultValue: defaultServiceRequestAddressID,
                }}
            />
            <GeneralSetting 
                formDef={{
                    name: `authZero CConNonWebOrders`,
                    label: `Push updates on any order placed by this user.`,
                    type: FormType.CHECKBOX,
                }}
                label="Email CC on Non-Web Orders"
                tooltip="When enabled, this will send a CC email to the specified email address when an order is placed outside of the web portal."
            />
            <GeneralSetting 
                formDef={{
                    name: `authZero HideOtherUserOrders`,
                    label: `Hide auto toner, phone, and other users' web orders`,
                    type: FormType.CHECKBOX,
                }}
                label="Hide Others' Orders"
                tooltip="When enabled, this will hide all orders placed by other users in the web portal. They will not see them on grids, reports, or order history."
            />
    </>
}