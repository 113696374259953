import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Layout } from './layout';
import { FaviconTitleManager } from './favicon-title-manager/favicon-title-manager';
import ScrollToTop from '../../shared/scrollToTop/scrollToTop';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserProvider } from '../../context/UserContext';
import { MessagesProvider } from '../../context/MessagesContext';
import { withoutHeaderUrls } from './utils/constants';
import { CustomerProvider } from './context/CustomerContext';
import { CartProvider } from './context/CartContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Tenant } from '../../types/Tenant';

const hostName = window.location.hostname;


export const Client = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const withoutLayout = withoutHeaderUrls.some((url) => pathname.includes(url));
  const [params] = useSearchParams();
  const navigateToAuthentication = !!params.get('code');
  const navigateToMigration = params.get('error_description') === 'MIGRATION_PASSWORD_RESET_REQUIRED';
  const tenantName = useSelector(
    (state: RootState) => state.tenantState.tenantName,
  );

  /**
   * The auth0 settings, which changes based on the tenant.
   */
  const auth0Settings = useMemo(() => {
    const prodFlex = {
      audience: 'https://flexlayer.flextg.com',
      domain: 'flexprint.us.auth0.com',
      clientId: 'u9Mur1kJjnKTgVZmCfpcJnyhtxN5ftcJ'
    }
    switch (true) {
      case tenantName === Tenant.FLOTECH && (hostName.includes('localhost') || hostName.includes('portaltest')):
        return {
          audience: 'https://flexlayerstaging.flextg.com',
          domain: 'flotechdev.us.auth0.com',
          clientId: 'cFGmNaBEqX0Lh73awpsDeRmZFYpeNcgu'
        }
      case hostName.includes('localhost'):
      case hostName.includes('dev'):
      case hostName.includes('test'):
        return {
          audience: 'https://flexlayerstaging.flextg.com',
          domain: 'flexprintinc.us.auth0.com',
          clientId: 'EJ4uKr61H8JCkPwRZ0tKPkRQjfRKQIkQ'
        }
      case hostName.includes('portal.flotech.net'):
        return {
          audience: 'https://flexlayer.flextg.com',
          domain: 'flotech.us.auth0.com',
          clientId: 'vw5qBpS05Y2giTSAZIye3WDCMBeeTRWJ'
        }
      case hostName.includes('my.') || hostName.includes('portal.'):
        return prodFlex
      default:
        return prodFlex
    }
  }, [tenantName])



  // error_description=MIGRATION_PASSWORD_RESET_REQUIRED&state=Wjd%2BbEY2SmFRYVhjeW42dHBhTnFvaFl2MFdmUlZwTlh1R0d4ZnBZZGhDaQ%3D%3D

  //This is to check for the auth0 cookie (passed from auth0 on idp initiated login, and the code passed from auth0 on SP initiated login
  //tbh I really like this approach, there has to be something better no?
  useEffect(() => {
    if( window.location.pathname === '/' && navigateToMigration ) {
      return navigate('/auth-migration');
    }
    if( window.location.pathname === '/' && !params.get('connection') ) {
      navigate('/login');
    }
    if (navigateToAuthentication) {
      navigate('/authentication');
    }
  }, [])


  return <Auth0Provider
    domain={auth0Settings.domain}
    clientId={auth0Settings.clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: auth0Settings.audience,
    }}
  >
    <UserProvider>
      <MessagesProvider>

        <FaviconTitleManager />
        <ScrollToTop />
        <CustomerProvider>
          {withoutLayout ?
            <Outlet /> :
            <CartProvider>
              <Layout>
                <Outlet />
              </Layout>
            </CartProvider>
          }
        </CustomerProvider>

      </MessagesProvider>
    </UserProvider>
  </Auth0Provider>;
};
