import { Link } from 'react-router-dom';
import {
  getTime, isCheckDollerProcingNonZero,
  renderUSCurrency
} from "../../../../../../../utils/utils";
import { Chip } from '../../../../../../../components/ui/Chip';
import { useUser } from '../../../../../../../context/UserContext';
import { CustomerTokens } from '../../../../../../../types/Customer';

export const OrderTableFields = () => {
  const { hasPermission } = useUser();

  const filterParams = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
    debounceMs: 200,
  };

  return [
    {
      headerName: 'Order #',
      field: 'SOCode',
      filterParams: filterParams,
      width: 140,
      sortable: false,
      cellRenderer: (options: any) => {
        return (
          <Link
            className={`service-request-view ${
              hasPermission(CustomerTokens.ViewPlacedOrder)
                ? 'linkText'
                : ''
            }`}
            to={
              hasPermission(CustomerTokens.ViewPlacedOrder)
                ? `/customer/order-detail/${options.data.SOID}`
                : ''
            }
          >
            {options.data.SOCode}
          </Link>
        );
      },
    },
    {
      headerName: 'Order Placed',
      field: 'CreatedDate',
      width: 230,
      sortable: false,
      filter: false,
      renderCSVColumn: (record: any) => {
        return record?.CreatedDate
          ? getTime(record?.CreatedDate).toString()
          : '';
      },
      cellRenderer: (cell: any) => {
        if (cell && cell.data) {
          const Date = getTime(cell.data.CreatedDate);
          return <div>{Date}</div>;
        } else {
          return '';
        }
      },
    },
    {
      headerName: 'Status',
      width: 150,
      sortable: false,
      filterParams: filterParams,
      field: 'Status',
      renderCSVColumn: (options: any) => {
        return options.Status;
      },
      cellRenderer: (options: any) => {
        return <Chip status={options.value} />;
      },
    },
    {
      headerName: 'Order Type',
      field: 'OrderType',
      sortable: false,
      filterParams: filterParams,
      width: 140,
    },
    {
      headerName: 'Order items',
      field: 'OrderItem',
      sortable: false,
      filterParams: filterParams,
      width: 140,
      hide: true,
    },
    {
      headerName: 'Total Amount',
      field: 'OrderCost',
      sortable: false,
      filterParams: filterParams,
      width: 140,
      hide: true,
      renderCSVColumn: (record: any) => {
        return '$' + record?.OrderCost.toFixed(2);
      },
      cellRenderer: (options: any) => {
        return <span>{isCheckDollerProcingNonZero(options?.data?.OrderCost?.toFixed(2)) ? renderUSCurrency(options?.data?.OrderCost) : '-'}</span>;
      },
    },
  ];
};
