import React, {forwardRef, useImperativeHandle} from 'react';
import { useModal } from '../../../context/ModalConext';
import {colorCoder, getTime, isCheckDollerProcingNonZero, renderUSCurrency} from '../../../utils/utils';
import { ReactSelectCompo } from '../../../components/ui/react-select/react-select';
import { useSelector } from "react-redux";
import { Button } from '../../../components/ui';
import { TENANT } from "../../../constant/constant";
import { RootState } from "../../../store/store";
import { Chip } from '../../../components/ui/Chip';
import { useCart } from '../context/CartContext';

const AddCartProductExistDialog = ({}, ref: any) => {
  const tenantName = useSelector(
    (states: RootState) => states.tenantState.tenantName,
  );
  const { openModal, closeModal } = useModal();
  const { addToCart } = useCart();
  let orderReason = [];

  const openPopUpModal = ({
                            productDetails,
                            orderDetails,
                          }) => {
    openModal({
      content: (
        <>
          <div className="p-6 modalBody ">
            <div className="p-4 text-sm bg-orange-200 rounded mb-4">
              We see a recent order was already placed for this device and
              color. Please review the details below to figure out if you still
              need this supply item
            </div>
            <div className="text-sm mb-4">
              <strong>Order Item:</strong>{' '}
              <div className="break-all">
                {productDetails && productDetails.ItemDescription}
              </div>
            </div>

            <div className="orderInfoContent">
              <div className="border border-gray-200 rounded-lg text-sm mb-8">
                <div className="flex flex-wrap orderHeading p-6 bg-gray-100 items-start">
                  <div className="w-full md:pr-8 sm:flex flex-wrap xl:gap-4 ">
                    <div className="xl:w-auto md:w-1/2 xl:mb-0 mb-2 pr-4">
                      <div className="text-gray-600">Order No.</div>
                      <div className="text-gray-800 font-medium xl:mt-2">
                        <a
                          href={'/customer/order-detail/' + orderDetails?.SOID}
                          target={'_blank'}
                          className="text-gray-800 font-medium linkText"
                        >
                          {orderDetails && orderDetails.SONumber}
                        </a>
                      </div>
                    </div>

                    {orderDetails && orderDetails.PONumber ? (
                      <div className="xl:w-auto md:w-1/2 xl:mb-0 mb-2 pr-4">
                        <div className="text-gray-600">Customer PO</div>
                        <div className="text-gray-800 font-medium xl:mt-2">
                          {orderDetails.PONumber}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {orderDetails && orderDetails.OrderType ? (
                      <div className="xl:w-auto md:w-1/2 xl:mb-0 mb-2 pr-4">
                        <div className="text-gray-600">Order Type</div>
                        <div className="text-gray-800 font-medium xl:mt-2">
                          {orderDetails.OrderType}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {orderDetails && orderDetails.CreatedDate ? (
                      <div className="xl:w-auto md:w-1/2 xl:mb-0 mb-2 pr-4">
                        <div className="text-gray-600">Order Placed</div>
                        <div className="text-gray-800 font-medium xl:mt-2">
                          {getTime(orderDetails.CreatedDate)}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {orderDetails &&
                    orderDetails.OrderCost &&
                    isCheckDollerProcingNonZero(
                      orderDetails.OrderCost.toFixed(2),
                    ) ? (
                      <div className="xl:w-auto md:w-1/2 xl:mb-0 mb-2 pr-4">
                        <div className="text-gray-600">Total Amount</div>
                        <div className="text-gray-800 font-medium xl:mt-2">
                          {renderUSCurrency(orderDetails.OrderCost)}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {orderDetails &&
                    orderDetails.TrackingAPIData &&
                    orderDetails.TrackingAPIData.trackingNumber ? (
                      <div className="xl:w-auto md:w-1/2 xl:mb-0 mb-2 pr-4">
                        <div className="text-gray-600">Tracking Number</div>
                        <div className="text-gray-800 font-medium xl:mt-2">
                          <a
                            href={
                              '/customer/order-tracking/' +
                              orderDetails.TrackingAPIData.trackingNumber
                            }
                            className="text-gray-800 font-medium linkText"
                            target="_blank"
                          >
                            {orderDetails.TrackingAPIData.trackingNumber}
                          </a>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {(orderDetails &&
                    orderDetails?.TrackingAPIData &&
                    orderDetails?.TrackingAPIData?.ScheduleDelivery) ? (
                      <div className="xl:w-auto md:w-1/2 xl:mb-0 mb-2 pr-4">
                        <div className="text-gray-600">
                          Estimated Delivery Date
                        </div>
                        <div className="text-gray-800 font-medium xl:mt-2">
                          {orderDetails.TrackingAPIData.ScheduleDelivery.datetime}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {orderDetails && orderDetails.Status ? (
                      <div className="xl:w-auto md:w-1/2 xl:mb-0 mb-2 pr-4">
                        <div className="text-gray-600">Status</div>
                        <div className="mt-2">
                          <Chip status={orderDetails.Status}/>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                {orderDetails &&
                  orderDetails.orderItems &&
                  orderDetails.orderItems.length &&
                  orderDetails.orderItems.map((item: any, index: any) => (
                    <div key={'orderExist' + index}>
                      <div className="orderListBar">
                        <div className="p-4 w-full border-b bordr-gray-300 orderListItem">
                          <div className="lg:flex items-center gap-6">
                            <div className="2xl:w-9/12 lg:w-3/5 text-sm">
                              <div className="flex">
                                <div className="w-4 mr-2 ">
                                  {tenantName === TENANT.MARIMON &&
                                  item &&
                                  item.ItemDescription &&
                                  colorCoder(item?.ItemDescription) ? (
                                    <div
                                      className="w-4 h-4 rounded-full ItemDescription mt-1"
                                      style={{
                                        backgroundColor: colorCoder(
                                          item?.ItemDescription,
                                        ),
                                      }}
                                    ></div>
                                  ) : (
                                    ''
                                  )}
                                  {tenantName !== TENANT.MARIMON &&
                                  item &&
                                  item.CatDescription &&
                                  colorCoder(item.CatDescription) ? (
                                    <div
                                      className="w-4 h-4 rounded-full ItemDescription mt-1"
                                      style={{
                                        backgroundColor: colorCoder(
                                          item?.CatDescription,
                                        ),
                                      }}
                                    ></div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div className="text-gray-900 font-semibold break-all	">
                                  {item?.ItemDescription}
                                </div>
                              </div>
                              <div className="ml-6 sm:flex text-gray-600 mt-2">
                                <div className="mr-8">
                                  <span>{item?.Item}</span>
                                </div>
                                <div className="mr-8">
                                  {item?.EquipmentNumber ? (
                                    <span className="item-equipment">
                                      EQ# {item?.EquipmentNumber}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div className="mr-8">
                                  {item?.SerialNumber ? (
                                    <span className="item-equipment">
                                      SER# {item?.SerialNumber}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div className="mr-8">
                                  {item?.LocationRemarks ? (
                                    <span className="item-equipment">
                                      {item?.LocationRemarks}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="2xl:w-3/12 lg:w-2/5 lg:mt-0 mt-4 flex lg:justify-end">
                              <div className="sm:flex items-center gap-8 lg:justify-start justify-between">
                                {isCheckDollerProcingNonZero(
                                  item?.Price?.toFixed(2),
                                ) ? (
                                  <div className=" w-24 text-center lg:block flex sm:mb-0 mb-2">
                                    <div className="text-gray-900 font-semibold lg:mr-0 mr-3">
                                      Each
                                    </div>
                                    <div className="text-gray-600">
                                      {renderUSCurrency(item?.Price)}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {
                                  <div className="text-center lg:block flex sm:mb-0 mb-2">
                                    <div className="text-gray-900 font-semibold lg:mr-0 mr-3">
                                      Qty
                                    </div>
                                    <div className="text-gray-600">
                                      {item?.Quantity}
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="modalFooter lg:flex justify-between py-6 px-6 border-t border-gray-200 ">
            <div className="lg:m-0 mb-4 sm:flex items-center">
              <h6 className="mr-2 sm:mb-0 mb-4">
                Reason for reorder: <span className="text-red-600">*</span>
              </h6>
              <ReactSelectCompo
                name={'ViewAllItems'}
                label={''}
                labelledBy={'ViewAllItems'}
                options={[
                  {
                    label: 'Cannot find',
                    value: 'Cannot find',
                  },
                  {
                    label: 'Defective',
                    value: 'Defective',
                  },
                  {
                    label: 'Wrong toner received',
                    value: 'Wrong toner received',
                  },
                ]}
                optionKeyMapObject={{
                  labelKey: 'label',
                  valueKey: 'value',
                }}
                value={orderReason}
                disableSearch={true}
                hasFilterSelectAll={false}
                hasSelectAll={false}
                isSingleSelect={true}
                isCheckboxVisible={false}
                ClearSelectedIcon={null}
                onChange={(event: any) => {
                  orderReason = event;
                }}
                closeOnChangedValue={true}
                className="sm:w-80 w-full"
                isMenuTop={true}
              ></ReactSelectCompo>
            </div>
            <div className="sm:flex items-center">
              <h6 className="sm:mb-0 mb-4 mr-2">Do you still want to order?</h6>
              <div className="flex">
                <button
                  type="button"
                  className="borderedBtn mr-2 linkText hover:text-black"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
                <Button
                  className="rounded px-4 py-2"
                  onClick={() => {
                    addToCart(productDetails, false);
                    closeModal();
                  }}
                >
                  Order Again
                </Button>
              </div>
            </div>
          </div>
        </>
      ),
      options: {
        title: 'Order Info',
        className: 'orderInfoModal',
        modelWidthClass: 'w-full',
        footer: <></>,
      },
    });
  }
  useImperativeHandle(ref, () => ({
    openPopUpModal({product, orderDetails}) {
      openPopUpModal({
        productDetails: product,
        orderDetails: orderDetails,
      });
    },
  }));
  return <></>;
};

export default forwardRef(AddCartProductExistDialog);
