import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import {
  CustomerAddress,
  CustomerInfo,
  CustomerTokens,
  mockCustomer,
} from '../types/Customer'
import { useFlexLayer } from '../hooks/useFlexlayer'
import { ClientStorageKey } from '../constant/constant'
import { withOutAuthUrls } from '../module/client/utils/constants'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { isArray } from 'lodash'

interface UserContextFunctions {
  customerInfo: CustomerInfo
  login: ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => Promise<{ customerInfo: CustomerInfo | null; error: string | boolean }>
  eAutoLogin: ({
    Email,
    Password,
  }: {
    Email: string
    Password: string
  }) => Promise<{ customerInfo: CustomerInfo | null; error: string | boolean }>
  selectCustomer: (customerId: number) => void
  loading: boolean
  isAuthenticated: boolean
  redirectUrl: string
  customerAddresses: CustomerAddress[]
  hasPermission: (permission: CustomerTokens | CustomerTokens[]) => boolean
  logout: () => void
  checkLogin: () => void
  getCustomerAddresses: (customerId: number) => void
}

const initalUserContext: CustomerInfo = {
  ...mockCustomer,
}

const UserContext = createContext<UserContextFunctions | undefined>(undefined)

export const UserProvider = ({
  children,
  isAdmin = false,
}: {
  children: React.ReactNode
  isAdmin?: boolean
}) => {
  const { flexLayer } = useFlexLayer()
  const {
    user,
    getAccessTokenSilently,
    logout: authZeroLogout,
    loginWithRedirect,
    isLoading,
  } = useAuth0()
  const [params] = useSearchParams()
  const [isLoggingInWithRedirect, setIsLoggingInWithRedirect] = useState(false)

  const navigate = useNavigate()

  const location = useLocation()

  const [state, dispatch] = useReducer(
    (userState: CustomerInfo, update: Partial<CustomerInfo>) => {
      localStorage.setItem(
        ClientStorageKey.customerInfo,
        JSON.stringify({ ...userState, ...update }),
      )
      return { ...userState, ...update }
    },
    initalUserContext,
  )

  const [customerAddresses, setCustomerAddresses] = useState<CustomerAddress[]>(
    [],
  )

  const isAuthenticated = state.EmailAddress ? true : false

  const [loading, setLoading] = useState(
    !!localStorage.getItem(ClientStorageKey.ACCESS_TOKEN),
  )
  const [redirectUrl, setRedirectUrl] = useState<string>(
    window.location.pathname,
  )

  let partialMatch = withOutAuthUrls.find((url) => {
    return url
      .toLowerCase()
      .includes(window.location.pathname.split('/')[1].toLowerCase())
  })

  const eAutoLogin = async ({ Email, Password }) => {
    const { data } = await flexLayer('/users/eAutomate/login', {
      Email,
      Password,
    })
    const { userInfo } = data
    if (userInfo) dispatch(userInfo)
    return {
      customerInfo: userInfo,
      error: userInfo ? false : 'Invalid email or password',
    }
  }

  const login = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    const response: any = await flexLayer('/users/login', { email, password })
    if (response.error)
      return { customerInfo: null, error: response.error.error.Message }
    const { customerInfo } = response.data as { customerInfo: CustomerInfo }
    if (customerInfo) dispatch(customerInfo)
    return {
      customerInfo,
      error: customerInfo ? false : 'Invalid email or password',
    }
  }

  const getCustomerAddresses = async (customerId: number) => {
    const { data, error } = await flexLayer(`/customers/${customerId}/address`)
    if( error ) {
      console.error(error)
      return;
    }
    setCustomerAddresses(data)
  }

  const selectCustomer = async (customerId: number, userId?: string) => {
    userId = userId || state.UserId
    //Why is this a put request? -__-
    //We have all the info we need to do this client side, should refactor this out and just update the state
    const { data } = await flexLayer(
      `/users/${userId}/customer/${customerId}`,
      {
        allowAllCustomers: state.AllowAllCustomers,
      },
      { method: 'PUT' },
    )

    const { customerInfo } = data
    if (customerInfo) dispatch(customerInfo)
    navigate('/dashboard')

    //Get Customer addresses and store them to use around the app
    getCustomerAddresses(customerId)
  }

  const logout = async () => {
  //Logout for legacy auth
    localStorage.clear()
    dispatch(initalUserContext)

    //Logout of AuthZero
    if (user)
      await authZeroLogout({
        logoutParams: { returnTo: window.location.origin + '/login' },
      })

    setLoading(false)
    const isSSO = state.isSSO
    if (isSSO) {
      navigate('/sso-logout')
    } else {
      navigate('/login')
    }
  }

  const checkLogin = async () => {
    setLoading(true)

    const sessionId = params.get('sessionId')
      ? `?sessionId=${params.get('sessionId')}`
      : ''
    const res = await flexLayer(`/users/checkLogin${sessionId}`, {}, {})

    // "ObjectNotFound" occurs when a user doesn't exist on Mongo.
    if (['ObjectNotFound', 'TokenExpiredError', 'JsonWebTokenError'].includes(res?.error?.error?.Name)) {
      return logout()
    }

    if (res.error && !partialMatch) {
      setLoading(false)
      return navigate('/login')
    }

    dispatch(res.data.customerInfo)
    if (res?.data?.customerInfo && redirectUrl !== '/login') {
      navigate(redirectUrl)
    }

    //Get Customer addresses and store them to use around the app
    getCustomerAddresses(res.data.customerInfo.CustomerId)
    setLoading(false)
  }

  const hasPermission = (permission: CustomerTokens | CustomerTokens[]) => {
    if (isArray(permission)) {
      return permission.some((perm) => state.Tokens.includes(perm))
    }
    return state.Tokens.includes(permission)
  }

  useEffect(() => {
    //TODO: Remove when admin side is conneted to main account
    if (isAdmin) return

    const hasToken = localStorage.getItem(ClientStorageKey.ACCESS_TOKEN)
    setRedirectUrl(window.location.pathname)

    // Check if the user is already logged in
    if (hasToken) checkLogin()

    if (!partialMatch && !hasToken) {
      const search = window.location.search
      window.location.href = '/login' + search
    }
  }, [])

  /**
   * Loads auth zero and auth zero state into the application context.
   * 
   * Also handles redirect-logins for idp-initiated auth.
   */
  const loadAuthZero = useCallback(async () => {

    // 1. Handle idp-initiated redirects
    const redirectConnection = params.get('connection')
    if (params.get('connection')) {
      setIsLoggingInWithRedirect(true)
      try {
        await loginWithRedirect({
          authorizationParams: {
            connection: redirectConnection,
          },
        })
        return
      } catch (err) {
      }
      setIsLoggingInWithRedirect(false)
    }

    // 2. Exit if any of the following are true.
    // NOTE: If we're logging in w/redirect or auth0 is loading (important), then don't do things
    if (
      (user && state.EmailAddress) ||
      location.pathname !== '/authentication' ||
      isLoggingInWithRedirect ||
      isLoading
    ) {
      return
    }

    // 3. If the user is logged in, update the state
    if (user?.userMetadata && !state.EmailAddress) {
      localStorage.setItem(
        ClientStorageKey.customerInfo,
        JSON.stringify(user.userMetadata),
      )
      dispatch(user.userMetadata)

      //If the user has multiple customers, we need to ask them to select a company
      if (
        user.userMetadata?.Customers.length > 1 ||
        user.userMetadata?.Customers.includes(-1)
      ) {
        navigate('/select-company')
      } else {
        selectCustomer(
          user.userMetadata?.Customers[0],
          user.userMetadata?.UserId,
        )
      }
      return
    }

    // 4. If we don't have a user, we need to ask for a token
    if (!user) {
      try {
        // when this function is successful; user is populated with the user info
        await getAccessTokenSilently()
      } catch (err) {

        // If a migration is required, go to the landing page
        if (err.message === 'MIGRATION_PASSWORD_RESET_REQUIRED') {
          return navigate('auth-migration')
        }
        // If the user is not logged in, go to the login page
        if (err.error === 'login_required' && !partialMatch) {
          return await logout()
        }

        return
      }
    }
  }, [
    state.EmailAddress,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    isLoggingInWithRedirect,
    location.pathname,
    params,
    isLoading,
  ])

  useEffect(() => {
    loadAuthZero()
  }, [loadAuthZero])

  return (
    <UserContext.Provider
      value={{
        customerInfo: state,
        login,
        eAutoLogin,
        selectCustomer,
        loading,
        isAuthenticated,
        redirectUrl,
        customerAddresses,
        hasPermission,
        logout,
        checkLogin,
        getCustomerAddresses
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  const context = useContext<UserContextFunctions | undefined>(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
