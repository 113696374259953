import { Ref, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Api } from '../../../../../../../shared/api/api';
import { WidgetHeader } from '../../../../../grid-widget-header/widget-header';
import ApiTable from '../../../../../../../shared/api-table/api-table';
import { ClientGridName } from '../../../../../../../constant/constant';
import { ServiceRequestTableFields } from './services-request-table-fields';
import { useUser } from '../../../../../../../context/UserContext';
import { CustomerTokens } from '../../../../../../../types/Customer';

export const ServiceRequest = ({
  title,
  viewAllRouterLink = null,
  plusRouterLink = null,
  accessToken = [],
  expandContractGrid,
  isExpand
}: any) => {
  const ref: Ref<any> = useRef(null);
  let { equipmentId } = useParams();
  const gridRef: Ref<any> = useRef(null);
  const headerRef: Ref<any> = useRef(null);
  const { customerInfo, hasPermission } = useUser();
  const [isApiCallProgress, setApiCallProgress] = useState(true);
  const [rowData, setRowData] = useState<any>(false);
  const gridBtnClassName = 'grid-export-btn-service-grid';

  const getLocationOrderData = async () => {
    try {
      setApiCallProgress(true);
      let searchQueryForRequestHistory: any = {
        CustomerId: {},
        EquipmentId: '',
        LocationID: '',
      };

      searchQueryForRequestHistory.CustomerId = customerInfo.CustomerId;
      searchQueryForRequestHistory.EquipmentId = equipmentId;
      searchQueryForRequestHistory.LocationID =
        customerInfo?.RestrictUserByLocationID &&
        customerInfo?.RestrictUserByLocationID?.length
          ? customerInfo?.RestrictUserByLocationID.toString()
          : null;
      const res = await Api.callAPI({
        url: `/api/v1/services/requestCalls/device`,
        method: 'PUT',
        body: searchQueryForRequestHistory,
        options: {
          successMessage: false,
          errorMessage: 'Error while getting service request by EquipmentId.',
        },
      });
      if (hasPermission(CustomerTokens.ViewServiceRequest)) {
        setRowData(res?.data || []);
      }
    } catch (e) {
    } finally {
      setApiCallProgress(false);
    }
  };

  const columns = ServiceRequestTableFields() as any;

  //Add column to the first index
  if(customerInfo.ShowTicketShareNumber) {
    columns.unshift({
      headerName: 'Ticket #',
      field: 'TicketNumbers',
      width: 130,
      minWidth: 130,
      sortable: false,
      filterParams: {
        filterOptions: ['contains'],
        debounceMs: 200,
        defaultOption: 'contains',
        maxNumConditions: 1,
      },
      cellRenderer: (cell: any) => {
        return cell.data.TicketNumbers?.replace(',', ', ');
      },
    });
  }

  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };

  const exportCSV = async () => {
    if (ref.current) {
      await ref.current.exportCSV();
    }
  };

  useEffect(() => {
    let dheight = 0;
    if (headerRef.current) {
      dheight += parseInt(headerRef.current.clientHeight);
    }

    if (gridRef.current) {
      gridRef.current.style.height = `calc(100% -  ${dheight}px)`;
    }
  }, [headerRef?.current]);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        (async () => {
          await getLocationOrderData();
        })();
      }, 10);
    }
  }, []);

  return (
    <>
      <div className={'w-full h-full pb-4'}>
        <div ref={headerRef}>
          <WidgetHeader
            viewAllRouterLink={viewAllRouterLink}
            plusRouterLink={plusRouterLink}
            title={title}
            resetGrid={resetGrid}
            accessToken={accessToken}
            expandContractGrid={expandContractGrid}
            isExpand={isExpand}
            exportCSV={exportCSV}
            gridBtnClassName={gridBtnClassName}
          />
        </div>
        <div ref={gridRef}>
          <ApiTable
            ref={ref}
            columns={columns}
            gridName={ClientGridName.DeviceServicesRequest}
            rowData={rowData || []}
            gridBtnClassName={gridBtnClassName}
            isLoading={isApiCallProgress}
            gridOptions={{
              pagination: false,
            }}
          />
        </div>
      </div>
    </>
  );
};
