import { CustomerTokens } from "../../types/Customer";

export const UserRoles = [
    { name: CustomerTokens.All, label: "Add/edit/delete users", value: CustomerTokens.All},
    { name: CustomerTokens.PlaceOrder, label: "Order supplies", value: CustomerTokens.PlaceOrder},
    { name: CustomerTokens.ViewPlacedOrder, label: "View supply orders", value: CustomerTokens.ViewPlacedOrder},
    { name: CustomerTokens.PlaceServiceRequest, label: "Request service", value: CustomerTokens.PlaceServiceRequest},
    { name: CustomerTokens.ViewServiceRequest, label: "View service requests", value: CustomerTokens.ViewServiceRequest},
    { name: CustomerTokens.RemoveDeviceLocations, label: "Add/edit devices & locations", value: CustomerTokens.RemoveDeviceLocations},
    { name: CustomerTokens.ModifyDeviceLocation, label: "Edit a device's location", value: CustomerTokens.ModifyDeviceLocation},
  ];