import TENANT_ACTIONS from './tenent.action-types'
import { Tenant } from '../../types/Tenant'
import type { Reducer } from 'redux'

// -- Types -- //
interface TenantState {
  tenantName: Tenant
}

// -- Constants -- //
/**
 * Gets the initial tenant. It appears that the ThemeContext is repeating
 * the work of which tenant we're using, thus this is exported.
 *
 * If possible, we should let redux be the single source of our tenant
 * state.
 */
export const getTenant = () => {
  const possibleTenants = [
    Tenant.MARIMON,
    Tenant.FLEXPRINT,
    Tenant.SANDBOX,
    Tenant.FLOTECH,
  ]
  const location = window.location.hostname
  let currentTenant = possibleTenants.find((tenant) =>
    location.includes(tenant),
  )
  if (!currentTenant) currentTenant = Tenant.FLEXPRINT //Will default to flexprint for Development & FTG URL
  // if (!currentTenant) currentTenant = Tenant.FLOTECH //Will default to flexprint for Development & FTG URL
  // if(!currentTenant) currentTenant = Tenant.MARIMON; //Will default to flexprint for Development & FTG URL

  return currentTenant
}

// -- Main -- //
const initialState: TenantState = {
  tenantName: getTenant(),
}

const tenantReducer: Reducer<TenantState> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action,
) => {
  switch (action.type) {
    case TENANT_ACTIONS.SET_TENANT: {
      return {
        ...state,
        tenantName: action.payload,
      }
    }
    default:
      return state
  }
}

export default tenantReducer
