import { FormDefinition, FormItem } from "../../../components/form";
import { Tooltip } from "../../../components/ui/ToolTip";

interface IGeneralSetting {
    formDef: FormDefinition | FormDefinition[],
    label: string,
    tooltip: string
  }
export const GeneralSetting: React.FC<IGeneralSetting> = ({ formDef, label, tooltip }) => {
  
    const formDefArray = Array.isArray(formDef) ? formDef : [formDef];
  
    return (
      <div className="sm:border-r border-gray-300 sm:pr-6 sm:border-b-0 border-b-2 sm:pb-0 pb-4">
        <Tooltip message={tooltip}>
        <div>
            <label className="font-medium mb-1 block text-gray-900 text-sm w-fit">
              {label}
            </label>
            {formDefArray.map((def, index) => (
            <FormItem
              key={`${label}-${index}`}
              className='customInput'
              formDef={def}
            />
          ))}
        </div>
        </Tooltip>
      </div>
    )
  }