import { createContext, useContext, useReducer } from 'react';
import { Tenant } from '../types/Tenant';
import { getTenant } from '../store/tenant/tenent.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { setTenant } from '../store/tenant/tenant.action';
import { RootState } from '../store/store';


export enum Themes {
    LIGHT = 0,
    DARK = 1,
    HOLDAY = 2, // Possible Holiday Theme for Christmas, Halloween, etc.
}

export const TeantThemesConstant = {
    [Tenant.FLEXPRINT]: {
        name: 'FTG',
        logo: '',
        favicon: '',
        primary: `bg-flexprint text-white`,
        secondary: '',
    },
    [Tenant.FLOTECH]: {
        name: Tenant.FLOTECH,
        logo: '',
        favicon: '',
        primary: `bg-flotech text-white`,
        secondary: '',
    },
    [Tenant.MARIMON]: {
        name: Tenant.MARIMON,
        logo: '',
        favicon: '',
        primary: `bg-marimon text-white`,
        secondary: '',
    },
    [Tenant.SANDBOX]: {
        name: Tenant.SANDBOX,
        logo: '',
        favicon: '',
        primary: `bg-sandbox text-white`,
        secondary: '',
    },
}

export const ThemesConstant = {
    [Themes.LIGHT]: {
        primary: 'bg-white text-stone-900',
        secondary: 'bg-stone-100 text-stone-900',
        warning: 'bg-yellow-400',
        success: 'bg-green-400',
        info: 'bg-blue-400',
        error: 'bg-red-400',
        text: 'text-stone-800',
        disabled: 'bg-stone-300 text-stone-400',
    },
    [Themes.DARK]: {
        primary: 'bg-stone-900 text-white',
        secondary: 'bg-stone-800 text-white',
        warning: 'bg-yellow-400',
        success: 'bg-green-400',
        info: 'bg-blue-400',
        error: 'bg-red-400',
        text: 'text-white',
        disabled: 'bg-stone-200 text-stone-400',
    },
}

export interface Theme {
    primary: string;
    secondary: string;
    warning: string;
    success: string;
    info: string;
    error: string;
    text: string;
    disabled: string;
}

interface TenantTheme extends Partial<Theme> {
    name: string;
    logo: string;
    favicon: string;
    primary: string;
}

interface ThemeContextInterface {
  theme: Themes;
  tenant: Tenant;
}

interface ThemeContextFunctions extends ThemeContextInterface {
  updateTheme: (theme: Themes) => void;
  mockTenant: (tenant: Tenant) => void;
  tenantTheme: TenantTheme;
  mainTheme: Theme;
}

const initalThemeContext: ThemeContextInterface = {
  theme: Themes.LIGHT,
  tenant: getTenant(),
};

const ThemeContext = createContext<ThemeContextFunctions | undefined>(undefined);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const reduxDispatch = useDispatch()
  const [ state, dispatch ] = useReducer((currentThemeState: ThemeContextInterface, update: Partial<ThemeContextInterface>) => {
    return { ...currentThemeState, ...update };
  }, initalThemeContext);
  const tenantName = useSelector((_state: RootState) => _state.tenantState.tenantName)
  const tenantTheme: TenantTheme = TeantThemesConstant[tenantName];
  const mainTheme = ThemesConstant[state.theme];

  const updateTheme = (theme: Themes) => dispatch({ theme: theme });
  const mockTenant = (tenant: Tenant) => reduxDispatch(setTenant(tenant))

  return <ThemeContext.Provider
    value={{
      ...state,
      updateTheme,
      tenantTheme,
      mainTheme,
      mockTenant,
    }}>
        { children }
    </ThemeContext.Provider>;

};

export function useTheme() {
  const context = useContext<ThemeContextFunctions | undefined>(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}
