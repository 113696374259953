//Returned from /getUserCustomerListFromId endpoint
export interface CustomerList {
  CustomerID: number
  Id: string
  CustomerName: string
  Name: string
  CustomerNumber: string
  City: string
  State: string
  Zip: string
  Address: string
  Active: boolean
  BranchID: number
  BranchNumber: string
  Country: string
  Phone1: string
  Email: string
  ATTN: string
  ShippingMethod: number
}

//Returned from /Location endpoint
export interface CustomerLocation {
  CustomerID: number
  LocationID: number
  CustomerName: string
  Address: string
  State: string
  City: string
  Zip: string
  TextVal?: string
  TotalServiceRequests: number
  TotalEquipments: number
  Attn: string
  Email: string
  Phone1: string
  Phone2: string
  CustomerNumber: string
  SOID: any
  TotalOrders: number
}

export interface CustomerInfo {
  EmailAddress: string
  Customers: number[]
  CustomerId: string
  BranchID: number
  BranchNumber: string
  CustomerAddress: string
  CustomerCity: string
  CustomerState: string
  CustomerZip: string
  CustomerCountry: string
  CustomerATTN: string
  CustomerName: string
  ShippingMethod: number
  CustomerPhone: string
  HideZeroDollarPricing: boolean
  HideEquipmentOrders: boolean
  ExemptFromFreightOnTransactionalOrders: boolean
  WarnCustomerOfPotentialDuplicateOrders: boolean
  ShowContractExpirationDate: boolean
  AllowRestrictRegion: boolean
  ShowRegionCodeInGrid: boolean
  AllowServiceRequestNumber: boolean
  Prefix: string
  CustomerCanViewGLCode: boolean
  AllowReportView: boolean
  CanAutoFillAttentionField: boolean
  CanPrependPONumberAttentionField: boolean
  SearchType: string
  FirstName: string
  LastName: string
  Tokens: CustomerTokens[]
  CanAccessCRM: boolean
  UserId: string
  WarehouseID: string
  UserPhone: string
  UserPhoneExtension: string
  RestrictUserByLocationID: any[]
  fieldMeta: FieldMeta
  HidefieldMeta: HidefieldMeta
  CConNonWebOrders: boolean
  AllowAllCustomers: boolean
  AllowHidingUsersWebOrders: boolean
  HideOtherUserOrders: boolean
  TriageW2Calls: boolean
  TriageOOACalls: boolean
  Note: string
  PrimarySymptom: any[]
  GlobalSettings: GlobalSettings
  ServiceRequestContactDefaultName: string
  ServiceRequestContactDefaultEmail: string
  ServiceRequestContactDefaultPhone: string
  SendEmailsWhenFCPOrdersAreCanceled: boolean
  isSSO?: boolean
  IsDefaultShippingAddress?: boolean
  DefaultShippingLocationID?: string
  orderPhone?: string
  AllUserOrderView?: boolean
  CreatedBy?: string
  CreatedDate?: string
  ssoData?: any
  CanViewGLCode?: boolean
  QuotesID?: any[]
  LongerLocationRemarks: boolean
  ShowTicketShareNumber?: boolean
}

export interface FieldMeta {
  poNumber: boolean
  orderPhone: boolean
  cc: boolean
  department: boolean
  floor: boolean
  address: boolean
  attention: boolean
  comments: boolean
  description: boolean
  email: boolean
  name: boolean
  orderEmail: boolean
  phone: boolean
  primarySymptom: boolean
  extendedNotes: boolean
}

export interface HidefieldMeta {
  poNumber: boolean
  orderPhone: boolean
  cc: boolean
  department: boolean
  floor: boolean
  address: boolean
  attention: boolean
  comments: boolean
  description: boolean
  email: boolean
  name: boolean
  orderEmail: boolean
  phone: boolean
  primarySymptom: boolean
  extendedNotes: boolean
}

export interface GlobalSettings {
  _id: string
  FreightCharges: number
  EnableCommunicationFromCustomersToZendesk: boolean
  CreateDate: string
  LastUpdate: string
}

export enum CustomerTokens {
  All = "ALL",
  PlaceOrder = "CAN_PLACE_ORDER",
  ViewPlacedOrder = "CAN_VIEW_PLACED_ORDER",
  PlaceServiceRequest = "CAN_PLACE_SERVICE_REQUEST",
  ViewServiceRequest = "CAN_VIEW_SERVICE_REQUEST",
  RemoveDeviceLocations = "CAN_ADD_REMOVE_DEVICE_LOCATION",
  ModifyDeviceLocation = "CAN_EDIT_DEVICE_LOCATION",
  ViewReport = "CAN_VIEW_REPORT",
  EditCostCenter = "CAN_EDIT_COST_CENTER"
}

export interface CustomerTokenSelection {
  name: CustomerTokens;
  label: string;
  value: CustomerTokens;
}

//Returned from /customers/:id/address endpoint
export interface CustomerAddress extends Partial<CustomerList> {
  SearchBy: string
}

export interface Customer {
    _id: string
    QuotesID: any[]
    AllowRestrictRegion: boolean
    ShowRegionCodeInGrid: boolean
    Id: string | number
    WarehouseID: string
    WarehouseName: string
    HideZeroDollarPricing: boolean
    SearchType: string
    AllowServiceRequestNumber: boolean
    CanViewGLCode: boolean
    Prefix: any
    HideEquipmentOrders: boolean
    CanAutoFillAttentionField: boolean
    ShowContractExpirationDate: boolean
    Name: string
    City: string
    State: string
    Zip: string
    Address: string
    Active: boolean
    Country: string
    Phone1: string
    Email: string
    ATTN: string
    ShippingMethod: number
    CreatedDate: number
    AllowReportView: boolean
    TriageOOACalls: boolean
    WarnCustomerOfPotentialDuplicateOrders: boolean
    SendEmailsWhenFCPOrdersAreCanceled: boolean
    AllowHidingUsersWebOrders: boolean
    CustomerID: number
    CustomerName: string
    CustomerNumber: string
    BranchID: number
    BranchNumber: string
}


export const mockCustomer: CustomerInfo = {
  EmailAddress: '',
  Customers: [],
  CustomerId: '',
  BranchID: 0,
  BranchNumber: '',
  CustomerAddress: '',
  CustomerCity: '',
  CustomerState: '',
  CustomerZip: '',
  CustomerCountry: '',
  CustomerATTN: '',
  CustomerName: '',
  ShippingMethod: 0,
  CustomerPhone: '',
  HideZeroDollarPricing: false,
  HideEquipmentOrders: false,
  ExemptFromFreightOnTransactionalOrders: false,
  WarnCustomerOfPotentialDuplicateOrders: false,
  ShowContractExpirationDate: false,
  AllowRestrictRegion: false,
  ShowRegionCodeInGrid: false,
  AllowServiceRequestNumber: false,
  Prefix: '',
  CustomerCanViewGLCode: false,
  AllowReportView: false,
  CanAutoFillAttentionField: false,
  CanPrependPONumberAttentionField: false,
  SearchType: '',
  FirstName: '',
  LastName: '',
  Tokens: [],
  CanAccessCRM: false,
  UserId: '',
  WarehouseID: '',
  UserPhone: '',
  UserPhoneExtension: '',
  RestrictUserByLocationID: [],
  fieldMeta: {
    poNumber: false,
    orderPhone: false,
    cc: false,
    department: false,
    floor: false,
    address: false,
    attention: false,
    comments: false,
    description: false,
    email: false,
    name: false,
    orderEmail: false,
    phone: false,
    primarySymptom: false,
    extendedNotes: false
  },
  HidefieldMeta: {
    poNumber: false,
    orderPhone: false,
    cc: false,
    department: false,
    floor: false,
    address: false,
    attention: false,
    comments: false,
    description: false,
    email: false,
    name: false,
    orderEmail: false,
    phone: false,
    primarySymptom: false,
    extendedNotes: false
  },
  CConNonWebOrders: false,
  AllowAllCustomers: false,
  AllowHidingUsersWebOrders: false,
  HideOtherUserOrders: false,
  TriageW2Calls: false,
  TriageOOACalls: false,
  Note: '',
  PrimarySymptom: [],
  GlobalSettings: {
    _id: '',
    FreightCharges: 0,
    EnableCommunicationFromCustomersToZendesk: false,
    CreateDate: '',
    LastUpdate: ''
  },
  ServiceRequestContactDefaultName: '',
  ServiceRequestContactDefaultEmail: '',
  ServiceRequestContactDefaultPhone: '',
  SendEmailsWhenFCPOrdersAreCanceled: false,
  isSSO: false,
  IsDefaultShippingAddress: false,
  DefaultShippingLocationID: '',
  orderPhone: '',
  LongerLocationRemarks: false,
  AllUserOrderView: false,
}