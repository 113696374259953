import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { Chip } from '../../../../../components/ui/Chip';
import { renderUSCurrency } from '../../../../../utils/utils';

export const OpenOrdersTableField = () => {
  const navigate = useNavigate();
  const filterParams = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
    debounceMs: 200,
  };
  return [
    {
      headerName: 'Order #',
      field: 'SOCode',
      width: 120,
      filterParams: filterParams,
      cellRenderer: (options: any) => (
        <Link
          className="service-request-view linkText"
          to={'/customer/order-detail/' + options.data.SOID}
        >
          {options.data.SOCode}
        </Link>
      ),
      onCellClicked: (options: any) => {
        navigate('/customer/order-detail/' + options.data.SOID);
      },
    },
    {
      headerName: 'Status',
      field: 'Status',
      width: 150,
      filterParams: filterParams,
      cellRenderer: (options: any) => {
        return <Chip status={options.data.Status} />;
      },
    },
    {
      headerName: 'Order Placed',
      width: 150,
      filterParams: filterParams,
      field: 'CreatedDate',
    },
    {
      headerName: 'Location',
      width: 150,
      filterParams: filterParams,
      field: 'CustomerName',
    },
    {
      headerName: 'Order Items',
      field: 'OrderItems',
      filterParams: filterParams,
      filter: null,
      sortable: false,
      autoHeight: 1,
      width: 250,
      valueGetter: (params: any) => params.data.OrderItems.join('\n'),
      cellRenderer: (options: any) => {
        let orderItems = '';
        if (options.data.OrderItems && options.data.OrderItems.length > 0) {
          options.data.OrderItems.forEach((orderItemObj: any) => {
            orderItems += `${orderItemObj.ItemDescription} - (${orderItemObj.Quantity})  \n`;
          });
        }
        return orderItems;
      },
    },
    {
      headerName: 'Total Amount',
      field: 'OrderCost',
      filterParams: filterParams,
      width: 100,
      cellRenderer: (options: any) => <span>{renderUSCurrency(options.data.OrderCost)}</span>,
    },
  ];
};
