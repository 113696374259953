import React, { Ref, useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import ApiTable from '../../../../shared/api-table/api-table';
import {
  ClientGridName,
  ClientStorageKey,
} from '../../../../constant/constant';
import { RequestServiceTableField } from './request-service-table-field';
import { ReactSelectCompo } from '../../../../components/ui/react-select/react-select';
import { filter } from 'lodash';
import { ResetIcon } from '../../../../assets/svg/reset-icon';
import { ExcelIcon } from '../../../../assets/svg/excel-icon';
import storage from '../../../../utils/storage/storage';
import { Input } from "../../../../components/ui";
import { useUser } from '../../../../context/UserContext';
import { useFlexLayer } from '../../../../hooks/useFlexlayer';

const ServiceHistory = () => {
  const requestFilterData: any = storage.getJSONItem(
    ClientStorageKey.RequestFilters,
  );
  const { customerInfo } = useUser();
  const ref: Ref<any> = useRef(null);
  const { flexLayer } = useFlexLayer();

  // const dispatch = useDispatch();
  const [isShowExportCsvBtn, setExportCsvBtn] = useState(false);
  const [selectLocations, setSelectedLocations] = useState<any>([]);
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [startDate, setStartDate] = useState(
    requestFilterData && requestFilterData?.timeRange !== null
      ? requestFilterData?.timeRange === 0
        ? ''
        : moment(new Date())
            .subtract(requestFilterData?.timeRange, 'months')
            .toISOString()
      : moment(new Date()).subtract(1, 'months').toISOString(),
  );
  const [totalRows, setTotalRows] = useState(0);

  const [onlyMyCallsRequest, setOnlyMyCallsRequest] = useState(
    requestFilterData && requestFilterData?.viewMyCalls
      ? requestFilterData?.viewMyCalls
      : false,
  );

  const [timeDropdownValue, setTimeDropdownValue] = useState(
    requestFilterData && requestFilterData?.timeRange !== null
      ? requestFilterData?.timeRange
      : 1,
  );
  const [isDropDownVisible, setDropdownVisible] = useState(false);
  const [locationDropDownValue, setLocationDropDownValue] = useState(null);
  const [locationDropdownOption, setLocationDropdownOption] =
    useState<any>(null);
  const timeStempDropDownOptions: any = [
    {
      label: 'All Time',
      value: '0',
    },
    {
      label: 'Last Month',
      value: '1',
    },
    {
      label: 'Last 3 Months',
      value: '3',
    },
    {
      label: 'Last 6 Months',
      value: '6',
    },
  ];

  //get request data
  const getRequesData = async (params: any) => {
    try {
      if (ref.current) {
        ref.current.showGridLoader();
      }
      let locations: any;
      if (selectLocations && selectLocations?.length) {
        locations = selectLocations.join(',');
      } else {
        locations =
          customerInfo?.RestrictUserByLocationID &&
          customerInfo?.RestrictUserByLocationID?.length
            ? customerInfo?.RestrictUserByLocationID &&
            customerInfo?.RestrictUserByLocationID?.join(',')
            : null;
      }

      const searchQuery = {
        onlyMyCallsRequest: onlyMyCallsRequest,
        LocationId: locations,
        StartDate: startDate,
        EndDate: endDate,
        CustomerId: customerInfo?.CustomerId,
        locationId: [],
        email: customerInfo?.EmailAddress,
        ...params,
      };

      const { data } = await flexLayer('/services/requestCalls', searchQuery, {
        method: 'PUT',
      });

      setTotalRows(data.totalRows || 0);
      return data;
    } catch (e) {
    } finally {
      if (ref.current) {
        ref.current.hideGridLoader();
      }
    }
  };
  const localFilterValue = (locationArr: any) => {
    if (storage.getItem(ClientStorageKey.RequestFilters)) {
      if (locationArr && locationArr.length) {
        let filterData: any = [];
        const requestFiltesData: any = storage.getJSONItem(
          ClientStorageKey.RequestFilters,
        );
        const locationIds = requestFiltesData?.location
          ? requestFiltesData?.location.split(',')
          : [];

        locationIds.map((locationId: any) => {
          const filterRecord = locationArr.filter(
            (lData: any) => lData.value.toString() === locationId,
          );
          if (filterRecord) {
            filterData = [...filterData, ...filterRecord];
          }
        });
        const convertDataFormDropdown: any = filterData.map((data: any) => {
          return {
            value: data.value,
            label: data.label,
          };
        });

        return {
          locations: convertDataFormDropdown,
        };
      }
    }
    return [];
  };
  // get address for dropdown
  const getShippingAddress = async () => {
    let locationArrIds: any = [];
    let locationArr: any = [];
    // dispatch(showLoader());
    try {
      const response = await flexLayer(`/customers/${customerInfo?.CustomerId}/address`);

      if (
        customerInfo?.RestrictUserByLocationID &&
        customerInfo?.RestrictUserByLocationID?.length
      ) {
        customerInfo?.RestrictUserByLocationID.forEach((location: any) => {
          const locations = filter(response.data, { CustomerID: location });
          locationArrIds.push(locations[0].CustomerID);
          locationArr.push(locations[0]);
        });
      } else {
        locationArr = response?.data;
      }

      const locationLabelValueArr: any = locationArr.map((data: any) => {
        return {
          value: data.CustomerID,
          label: data.SearchBy,
        };
      });

      setLocationDropdownOption([...locationLabelValueArr]);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const filterData: any = localFilterValue(
        locationArr.map((data: any) => {
          return {
            value: data.CustomerID,
            label: data.SearchBy,
          };
        }),
      );

      setLocationDropDownValue(
        filterData && filterData?.locations ? filterData?.locations : [],
      );

      setSelectedLocations(
        filterData && filterData.locations
          ? filterData.locations.map(({ value }: any) => value)
          : null,
      );

      setDropdownVisible(locationLabelValueArr?.length > 1);
      setTimeout(() => {
        storage.removeItem(ClientStorageKey.RequestFilters);
      }, 2000);
      return {
        data: locationArr,
      };
    } catch (e) {
    } finally {
      // dispatch(hideLoader());
    }
  };

  const resetGrid = () => {
    if (ref.current) {
      ref.current.resetGrid();
    }
  };
  const exportCSV = async () => {
    if (ref.current) {
      await ref.current.exportCSV();
    }
  };

  const columns = RequestServiceTableField({
    selectLocations,
    onlyMyCallsRequest,
    timeDropdownValue,
  });

    //Add column to the first index
    if(customerInfo.ShowTicketShareNumber) {
      columns.unshift({
        headerName: 'Ticket #',
        field: 'TicketNumbers',
        width: 130,
        minWidth: 130,
        sortable: false,
        filterParams: {
          filterOptions: ['contains'],
          debounceMs: 200,
          defaultOption: 'contains',
          maxNumConditions: 1,
        },
        cellRenderer: (cell: any) => {
          return cell.data.TicketNumbers?.replace(',', ', ');
        },
      });
    }

  useEffect(() => {
    getShippingAddress().then();
  }, []);

  return (
    <>
      <div>
        <h1 className="mb-4 text-2xl font-medium">Your Service Requests</h1>
        <div className="flex w-full items-center pb-4 border-b border-gray-200">
          <div className="sm:w-96 w-full locationSelect">
            {isDropDownVisible &&
            locationDropDownValue &&
            locationDropdownOption ? (
              <ReactSelectCompo
                options={locationDropdownOption}
                value={locationDropDownValue}
                name={'select location '}
                label={'Search Location'}
                selectAllValue='All Locations'
                overrideStrings={{ selectSomeItems: 'Search Location' }}
                // isOpen={true}
                hasFilterSelectAll={true}
                labelledBy={'location'}
                onClose={(option: any) => {
                  if (option && option?.length) {
                    const locationIdsArr = option.map(
                      ({ value }: any) => value,
                    );
                    setSelectedLocations(locationIdsArr);
                  } else {
                    setSelectedLocations(null);
                  }
                  setTimeout(() => {
                    if (ref.current) {
                      ref.current.refreshGrid();
                    }
                  }, 100);
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="lg:flex items-center justify-between my-4 w-full">
          <div className="flex items-center sm:justify-start justify-between">
            <div className="mr-4">
              <span className="md:text-lg sm:text-base text-sm font-semibold">
                {totalRows} {totalRows > 1 ? 'Requests' : 'Request'} placed in
              </span>
            </div>
            <div>
              {timeDropdownValue !== null ? (
                <ReactSelectCompo
                  name={'timestemp'}
                  className="w-40"
                  label={'Search Time Stemp'}
                  labelledBy={'timeStempo'}
                  disableSearch={true}
                  options={timeStempDropDownOptions}
                  value={timeStempDropDownOptions.filter(({ value }: any) => {
                    return value == timeDropdownValue.toString();
                  })}
                  isCheckboxVisible={false}
                  ClearSelectedIcon={null}
                  onChange={(selectedOp: any) => {
                    const data =
                      selectedOp && selectedOp.length ? selectedOp[0] : null;
                    const value = data?.value || timeDropdownValue;
                    let sDate,
                      eDate = new Date().toISOString();

                    if (parseInt(value) == 1) {
                      sDate = moment(eDate).subtract(1, 'months').toISOString();
                    } else if (parseInt(value) == 3) {
                      sDate = moment(eDate).subtract(3, 'months').toISOString();
                    } else if (parseInt(value) == 6) {
                      sDate = moment(eDate).subtract(6, 'months').toISOString();
                    } else {
                      sDate = '';
                      sDate = '';
                    }
                    setStartDate(sDate);
                    setEndDate(eDate);
                    setTimeDropdownValue(value);
                    if (ref.current) {
                      ref.current.refreshGrid();
                    }
                  }}
                  isSingleSelect={true}
                />
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="flex items-center justify-between lg:mt-0 mt-4">
            <div className="flex items-center relative">
              <label className="sm:leading-5 leading-4 flex items-center">

                <Input
                  type={'checkbox'}
                  placeholder={'View only my calls'}
                  onChange={(e: any) => {
                    setOnlyMyCallsRequest(e.target.checked);
                    if (ref.current) {
                      ref.current.refreshGrid();
                    }
                  }}
                />
                <div className='pl-2'>
                  View only <br className="sm:hidden block" /> my calls
                </div>
              </label>
            </div>

            <div className="flex">
              <button
                className={`flex items-center borderedBtn sm:ml-4 linkText hover:text-black`}
                onClick={resetGrid}
              >
                <ResetIcon />
                <div className={`ml-2 text-sm`}>Reset</div>
              </button>
              {isShowExportCsvBtn ? (
                <button
                  className={`flex items-center borderedBtn ml-4 linkText hover:text-black`}
                  onClick={exportCSV}
                >
                  <ExcelIcon />
                  <div className="ml-2 text-sm">Export CSV</div>
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
        <ApiTable
          ref={ref}
          gridName={ClientGridName.ServiceRequestHistory}
          columns={columns}
          gridDataService={getRequesData}
          setExportBtnVisible={setExportCsvBtn}
          gridOptions={{
            cacheBlockSize: 25,
            paginationPageSize: 25,
          }}
          exportCSVQueryParams={{
            rows: 5000,
          }}
        />
    </>
  );
};

export default ServiceHistory;
