import { FormType } from "../../../components/form";

//Fields that exist in the service request form
export const serviceRequestRequireFields = [
    {
      type: FormType.CHECKBOX,
      label: 'Primary Symptom',
      name: 'meta primarySymptom',
      value: true,
      disabled: true,
    },
    {
      type: FormType.CHECKBOX,
      label: 'Description',
      name: 'meta description',
      value: true,
      disabled: true,
    },
    {
      type: FormType.CHECKBOX,
      label: 'Comments/Special Requirements',
      name: 'meta comments',
    },
    {
      type: FormType.CHECKBOX,
      label: 'Name',
      name: 'meta name',
      value: true,
      disabled: true,
    },
    {
      type: FormType.CHECKBOX,
      label: 'Phone',
      name: 'meta phone',
      value: true,
      disabled: true,
    },
    {
      type: FormType.CHECKBOX,
      label: 'Email',
      name: 'meta email',
      value: true,
      disabled: true,
    },
  ];

  //Fields that exist in the checkout form
  export const checkoutRequireFields = [
    {
      label: 'PONumber',
      name: 'meta poNumber',
      type: FormType.CHECKBOX,
    },
    {
      label: 'Shipping Address',
      name: 'meta address',
      type: FormType.CHECKBOX,
      value: true,
      disabled: true,
    },
    {
      label: 'Attention',
      value: true,
      type: FormType.CHECKBOX,
      name: 'meta attention',
      disabled: true,
    },
    {
      label: 'Phone',
      type: FormType.CHECKBOX,
      name: 'meta orderPhone',
    },
    {
      label: 'Email',
      type: FormType.CHECKBOX,
      name: 'meta orderEmail',
      value: true,
      disabled: true,
    },
    {
      label: 'CC email',
      type: FormType.CHECKBOX,
      name: 'meta cc',
    },
    {
      label: 'Department',
      type: FormType.CHECKBOX,
      name: 'meta department',
    },
    {
      label: 'Floor',
      type: FormType.CHECKBOX,
      name: 'meta floor',
    },
  ];